import React, { useState, useEffect } from "react";

import Overview from "./Sections/Overview";
import Strategy from "./Sections/Strategy";
import SideBar from "../../shared/Sections/SideBar";
import OtherClients from "./Sections/OtherClients";
import EmailDesign from "./Sections/EmailDesign";
import Retro from "./Sections/Retro";
import OKL from "./Sections/OKL";
import SectionDivider from "../../shared/Sections/SectionDivider";
import DefineDiscover from "./Sections/DefineDiscover";
import OnsiteDesign from "./Sections/OnsiteDesign";
const EmailDesigns = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Set the fadeIn state to true after a delay of 3 seconds
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 500);

    // Clean up the timer to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const images = document.querySelectorAll("img");

    images.forEach((img) => {
      img.addEventListener("load", function () {
        img.classList.add("loaded");
      });
    });
  }, []);

  useEffect(() => {
    const videos = document.querySelectorAll("video");
    console.log(videos);

    videos.forEach((video) => {
      video.addEventListener("loadeddata", function () {
        console.log("Video loaded:", video);
        video.classList.add("loaded");
      });
    });

    // Clean up event listeners when the component unmounts
    return () => {
      videos.forEach((video) => {
        video.removeEventListener("loadeddata", function () {
          video.classList.add("loaded");
        });
      });
    };
  }, []);
  return (
    <>
      <SideBar
        className={`fadeSection ${fadeIn ? "fade-in" : ""}`}
        blocks={[
          { title: "Overview", id: "email-overview" },
          { title: "Case Study: One Kings Lane", id: "email-OKL" },
          { title: "Define & Discover", id: "email-define" },

          { title: "Strategy", id: "email-strategy" },
          { title: "Sign Up Modals", id: "onsite-design" },
          { title: "Email Design", id: "email-design" },
          { title: "Additional Client Examples", id: "email-otherClients" },
          { title: "Retrosepctives", id: "email-retro" },
        ]}
      />
        <div className="container">

      <section
        class={`introSection ${fadeIn ? "fade-in" : ""}`}
        id="email-overview"
      >
        <Overview />
      </section>
      <section class="projectSection" id="email-OKL">
        <SectionDivider />

        <OKL />
      </section>
      <section class="projectSection" id="email-define">
        <SectionDivider />
        <DefineDiscover />
      </section>

      <section class="projectSection" id="email-strategy">
        <SectionDivider />
        <Strategy />
      </section>
      <section class="projectSection" id="onsite-design">
        <SectionDivider />
        <OnsiteDesign />
      </section>
      <section class="projectSection" id="email-design">
        <SectionDivider />

        <EmailDesign />
      </section>
      <section class="projectSection" id="email-otherClients">
        <SectionDivider />

        <OtherClients />
      </section>

      <section class="projectSection" id="email-retro">
        <SectionDivider />

        <Retro />
      </section>
      </div>
    </>
  );
};

export default EmailDesigns;
