import React from "react";
import ExperienceItem from "./ExperienceItem";
import classes from "./AboutMe.module.css";

const Education = () => {
  return (
    <div className={` ${classes.grayContainer}`}>
      <h3 className="mb-10 displaySm ">Education</h3>
      <div className={`${classes.experienceContainer} `}>
        <div>
          <h3 className="titleLg">Washington University in St. Louis</h3>
          <p className="bodyRg">
            B.A in Economics, Human-Computer Interaction
          </p>
        </div>
      </div>
    </div>
  );
};

export default Education;
