import React, { useState, useEffect } from "react";
import Projects from "./Home/Projects";
import { motion } from "framer-motion";

import AnimatedTitle from "../shared/Sections/AnimatedTitle";
const Homepage = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Set the fadeIn state to true after a delay of 3 seconds
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 500);

    // Clean up the timer to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="container">
        <div className="gridContainer">
          {" "}
          <div className="divBlock2"></div>
          <div className={`description ${fadeIn ? "fade-in" : ""}`}>
            <h1 className="description-header">
              <AnimatedTitle
                title="Hello, I'm Xiao. I'm a product designer based in NYC passionate
            about storytelling and crafting joyful&#127803; experiences."
              />{" "}
            </h1>

            {/* <p style={{ fontSize: "22px" }}>
            Previously designing digital products at Wunderkind.
          </p> */}
          </div>
          <motion.div
            initial={{ opacity: 0 }} // Initial state (hidden and slightly below)
            animate={{ opacity: 1 }} // Animate to visible and original position
            transition={{ duration: 0.6, delay: fadeIn ? 0 : 2.5 }} // Adjust duration and delay
            className={`homeDivider`}
          ></motion.div>
          <motion.div
            initial={{ opacity: 0 }} // Initial state (hidden and slightly below)
            animate={{ opacity: 1 }} // Animate to visible and original position
            transition={{ duration: 0.6, delay: fadeIn ? 0 : 2.5 }} // Adjust duration and delay
            className={`pill`}
          >
            <p>Recent Work</p>
          </motion.div>
        </div>

        <Projects />
      </div>
    </>
  );
};

export default Homepage;
