import Paragraph from "../../../shared/Sections/Paragraph";
import ImageParagraph from "../../../shared/Sections/ImageParagraph";
import ImageFullWidth from "../../../shared/Sections/ImageFullWidth";
import Userflow from "../../../images/email-designs/User flow.png";
const Strategy = () => {
  return (
    <>
      <section className="content-block">
        <Paragraph
          title="03"
          subTitle="Segmentation: Design highly personalized buyer journeys "
          classesH1="mb-50"
          subTitle2="Conversion focus"
          copy="Emphasizes conversions for low-value items by highlighting discounts. This approach targets price-sensitive customers and leverages first-time customer incentives to drive initial purchases."
        />

        <Paragraph
          subTitle2="Relationship-driven approach"
          copy={
            <>
              Rather than bombarding users with discounts, high-value furniture
              often requires a more nuanced and relationship-driven approach to
              nurture potential customers. Likewise, users expressing interest
              in our design consult and trade programs offer similar
              relationship-building opportunities. <br></br> <br></br> The
              registration forms for these services are extensive, especially
              the Trade program, which requires certificate uploads—a
              potentially discouraging high-effort step that may lead to
              abandonment. If we can capture users' email addresses during their
              exit intent, we can later nurture and encourage them to sign up
              through OKL's own communication channels (email/text).
            </>
          }
        />
        <ImageFullWidth image={Userflow} />
      </section>
    </>
  );
};

export default Strategy;
