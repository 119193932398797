import CaseStudyIntro from "../../../shared/Sections/Intro";
import Email from "../../../images/navheadshot/project-page-hero-email.png";
const Overview = () => {
  return (
    <>
      <CaseStudyIntro
        copy="Personalize marketing outreach at scale with behavioral targeting 
      "
        image={Email}
        overviewCopy={
          <>
            Wunderkind is an agency that helps brands shape customer engagement
            through behavioral targeting, which is using website behavior
            analysis to create personalized messaging to improve the performance
            of their marketing campaigns.
            <br></br> <br></br>I owned the CX design strategy for onsite sign-up
            and abandonment emails for over 10 client brands. The projects I
            worked on have been met with outstanding praise from clients and
            have significantly contributed to increased conversion rates and
            digital revenues for these brands.
          </>
        }
        blocks={[
          {
            title: "Teams I worked with",
            copy: ["Customer Success", "Email Engineer", "Onsite Engineer"],
          },
          {
            title: "Brands I worked with",
            copy: [
              "One Kings Lane",
              "Fashion Nova",
              "Forever 21",
              "EveryPlate",
              "Rag & Bone",
              "...",
            ],
          },
        ]}
        blocks2={[
          {
            title: "My role",
            copy: [
              {
                type: "text",
                value: "Content Strategy",
              },
              {
                type: "text",
                value: "Segmentation",
              },
              {
                type: "text",
                value: "Visual Design",
              },
              {
                type: "text",
                value: "Testing",
              },
            ],
          },
        ]}
      />
    </>
  );
};
export default Overview;
