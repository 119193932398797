import React from "react";
import Paragraph from "../../../../shared/Sections/Paragraph";
import Card from "../../../../shared/Sections/Card";
import TransparencyIcon from "../../../../images/icons/Transparency.svg";
import DiversityIcon from "../../../../images/icons/Diversity.svg";
import FeedbackIcon from "../../../../images/icons/Feedback.svg";

const DesignPrinciple = () => {
  return (
    <div className="py-20 container">
      <Paragraph
        subTitle="The UX in AI-driven Recommendation"
        copy="To design a more user-centric recommendation email for repeat purchase, I examined the practices of leading tech companies such as Amazon, Spotify, and Netflix. This leads to insights and north star principles for implementing best practices in our design. "
      />
      <div className="gridContainer tp-20">
        <div className="divBlock4">
          <Card
            icon={FeedbackIcon}
            itemTitle="Feedback"
            copy="Providing clear feedback mechanisms is essential for refining and continuously improving recommendations."
          />
        </div>
        <div className="divBlock4">
          <Card
            icon={DiversityIcon}
            itemTitle="Diversity"
            copy="Offering diverse content, ranging from general (segment-level) to granular (item-level) recommendations."
          />
        </div>

        <div className="divBlock4">
          <Card
            icon={TransparencyIcon}
            itemTitle="Transparency"
            copy="Informing users about the sources of data utilized."
          />
        </div>
      </div>
    </div>
  );
};

export default DesignPrinciple;
