import Paragraph from "../../../shared/Sections/Paragraph";

import designProcess from "../../../images/MedicalSchool/Design Process.png";
import ImageFullWidth from "../../../shared/Sections/ImageFullWidth";

const Context = () => {
  return (
    <>
      {" "}
      <section class="content-block">
        <Paragraph
          title="01"
          classesH1="mb-50"
          subTitle="Context"
          subTitle2="The challenge"
          copy={
            <>
              <p>
                Patient encounter documentation is crucial in healthcare. Yet,
                the clinical rotation program director has identified a
                recurring issue with the accuracy of data entered by students.
                They believed that this problem is attributed to an outdated UI
                and wanted to find a way to simplify and streamline data entry
                for students, with the ultimate goal of improving overall
                accuracy and effectiveness.
              </p>
            </>
          }
        />
        {/* <Paragraph
        classes="mb-50"
        classesH1="mb-50"
        title="Problem"
        subTitle="Students don’t perceive patient encounter documentation as valuable to their education"
        subTitle2="Administrative, or Educational?"
        copy={
          <>
            <p>
              Through interviews, a common sentiment emerged that students often
              view patient encounter documentation as a burden for
              administrative requirement rather than a valuable educational
              tool. Our intention is to reshape the perception of patient
              encounter logs, shifting them from mere administrative
              requirements to effective documentation tools that actively
              support students in accomplishing their medical education
              objectives.
            </p>
          </>
        }
      /> */}
        <Paragraph
          subTitle2="My contribution"
          copy="I led various aspects including interviews, user flows, testing, and visual design. I served as the main POC in the communication with medical students and the Dean of Educational Technology.
        "
        />
        <ImageFullWidth image={designProcess} />
      </section>
    </>
  );
};

export default Context;
