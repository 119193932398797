import React, { useState, useEffect } from "react";
import "./MedicalSchool.module.css";

import SideBar from "../../shared/Sections/SideBar";

import Overview from "./Sections/MedicalOverview";
import ProblemDiscovery from "./Sections/ProblemDiscovery";
import Ideation from "./Sections/Ideation";
import UpdatedFlow from "./Sections/UpdatedFlow";
import FinalPrototpes from "./Sections/FinalPrototpes";
import WireframeSection from "./Sections/Wireframe";
import Retrosepctives from "./Sections/Retrospectives";
import Context from "./Sections/Context";
import SectionDivider from "../../shared/Sections/SectionDivider";
import VisualDesign from "./Sections/VisualDesign";
import ExistingSystem from "./Sections/ExistingSystem";

const MedicalSchool = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Set the fadeIn state to true after a delay of 3 seconds
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 500);

    // Clean up the timer to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const images = document.querySelectorAll("img");

    images.forEach((img) => {
      img.addEventListener("load", function () {
        img.classList.add("loaded");
      });
    });
  }, []);
  useEffect(() => {
    const videos = document.querySelectorAll("video");
    console.log(videos);

    videos.forEach((video) => {
      video.addEventListener("loadeddata", function () {
        console.log("Video loaded:", video);
        video.classList.add("loaded");
      });
    });
    // Clean up event listeners when the component unmounts
    return () => {
      videos.forEach((video) => {
        video.removeEventListener("loadeddata", function () {
          video.classList.add("loaded");
        });
      });
    };
  }, []);
  return (
    <>
      <SideBar
        className={`fadeSection ${fadeIn ? "fade-in" : ""}`}
        blocks={[
          { title: "Overview", id: "medical-overview" },
          { title: "Context", id: "medical-context" },
          { title: "Problem Discovery", id: "medical-problem" },
          { title: "Existing Systems", id: "medical-existingsystem" },
          { title: "Opportunity", id: "medical-ideation" },
          { title: "User Flow", id: "medical-userflow" },
          { title: "Wireframe & Testing", id: "medical-wireframe" },
          { title: "Visual Design", id: "medical-visual" },
          { title: "Final Prototypes", id: "medical-prototypes" },
          { title: "Retrosepctives", id: "medical-retrospective" },
        ]}
      />
      <section
        class={`introSection ${fadeIn ? "fade-in" : ""}`}
        id="medical-overview"
      >
        <Overview />
      </section>
      <section class={`projectSection`} id="medical-context">
        <SectionDivider />

        <Context />
      </section>
      <section
        class={`projectSection ${fadeIn ? "fade-in" : ""}`}
        id="medical-problem"
      >
        <SectionDivider />

        <ProblemDiscovery />
      </section>
      <section
        class={`projectSection ${fadeIn ? "fade-in" : ""}`}
        id="medical-existingsystem"
      >
        <SectionDivider />

        <ExistingSystem />
      </section>
      <section class="projectSection" id="medical-ideation">
        <SectionDivider />
        <Ideation />
      </section>
      <section class="projectSection" id="medical-userflow">
        <SectionDivider />
        <UpdatedFlow />
      </section>
      <section class="projectSection" id="medical-wireframe">
        <SectionDivider />
        <WireframeSection />
      </section>
      <section class="projectSection" id="medical-visual">
        <SectionDivider />
        <VisualDesign />
      </section>{" "}
      <section class="projectSection" id="medical-prototypes">
        <SectionDivider />
        <FinalPrototpes />
      </section>
      <section class="projectSection" id="medical-retrospective">
        <SectionDivider />
        <Retrosepctives />
      </section>
    </>
  );
};

export default MedicalSchool;
