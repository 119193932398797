import React from "react";
import ExperienceItem from "./ExperienceItem";
import classes from "../Info/AboutMe.module.css";

const Experience = () => {
  return (
    <div className={classes.grayContainer}>
      <div className="flex gap-20  pb-10">
        <h3 className="mb-10 displaySm ">Experience</h3>
        <p className="">
          I've worked with notable clients in Fashion, Tech, Food, Luxury,
          Beauty, Travel, Home, Sports and Health. Some of those include:
          Uniqlo, Everlane, Casemate, Fashion Nova, Forever 21, MoMA Design
          Store, Rag & Bone, U Beauty, One Kings Lane, Tarte, Shiseido,
          Corkcicle, Everyplate
        </p>
      </div>
      <ul className={`${classes.experienceContainer} pl-10`}>
        <ExperienceItem
          time="2023-present"
          title="Freelance Designer"
          description="Brand design, Logo, Web design, Booth design"
        />
        <ExperienceItem
          time="2021-2023"
          title="UX/UI Designer, Customer Experience"
          company="Wunderkind"
          description="I helped e-commerce brands connect with their customers through personalized messaging."
        />
      </ul>
    </div>
  );
};
export default Experience;
