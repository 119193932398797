import React from 'react';
import EmailDesigns from '../../components/EmailDesigns/EmailDesigns';

const EmailDesignsProject = () => {
	return (
		<>
			<EmailDesigns />
		</>
	);
};

export default EmailDesignsProject;
