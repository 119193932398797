import React from 'react';

import classes from './Input.module.css';

const Input = React.forwardRef((props, ref) => {
	return (
		<>
			<input
				className={`${classes.input} ${props.classes}`}
				ref={ref}
				type={props.type}
				id={props.id}
				value={props.value}
				placeholder={props.placeholder}
				onChange={props.onChange}
				onBlur={props.onBlur}
			/>
		</>
	);
});

export default Input;
