import React from "react";
import classes from "./Section.module.css";

const Card = (props) => {
  return (
    <div>
      <div style={{ padding: "50px", backgroundColor: "#FAFDFF" }}>
        <div
          className="flex mb-10"
          style={{ gap: "20px", alignContent: "center" }}
        >
          {props.icon && <img style={{ width: "40px" }} src={props.icon} />}
          {props.itemTitle && (
            <h3 className="titleLg " style={{ color: "#3671EE" }}>
              {props.itemTitle}
            </h3>
          )}
        </div>

        {props.copy && <p className="bodySm">{props.copy}</p>}
      </div>
    </div>
  );
};

export default Card;
