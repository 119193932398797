import React from "react";
import Paragraph from "../../../../shared/Sections/Paragraph";
import DisplayTitle from "../../../../shared/Sections/DisplayTitle";
import Insights from "../../../../shared/Sections/Insights";
import SpeedIcon from "../../../../images/icons/Speed.svg";
import CartIcon from "../../../../images/icons/Cart.svg";
import DiscountIcon from "../../../../images/icons/Discount.svg";

const Intro = () => {
  return (
    <div className="container">
      <div className="mb-100">
        <DisplayTitle
          overline="Section 1"
          title="The future of marketing emails: people-centric communication"
        />
        <Paragraph
          subTitle="Product discovery emails overview"
          copy={
            <>
              In the past, Wunderkind’s email solution only focus on triggered
              emails. As the second designer on the team, I collaborated closely
              with my manager to develop frameworks and templates for Product
              Discovery Emails from 0 to 1. These email modules enable brands to
              personalize their seasonal marketing campaigns, such as "new
              arrivals" and "sales," by leveraging past behavior data to suggest
              new items to customers. After beta testing with three client
              brands, the project was successfully launched in March 2023.
              <br></br>
              <br></br>
              My contribution main contribution included:
              <br></br> <br></br>
              <ul>
                <li>
                  Conduct research to guide personalized product discovery email
                  design best practice.
                </li>
                <li>
                  Develop flexible templates that maintain a consistent UX while
                  allowing for brand-specific customization.
                </li>
              </ul>
            </>
          }
        />
      </div>
      <DisplayTitle
        overline="Defining the problem space"
        title="An opportunity to drive email personalization beyond first purchase"
      />
      <div className="gridContainer">
        <div className="divBlock4">
          <h3>One of the biggest challenges in ecommerce is retention</h3>
        </div>
        <div className="divBlock1"></div>
        <div className="divBlock6">
          <Insights
            blocks={[
              {
                icon: CartIcon,
                copy: "Approximately 80% of shoppers make only a one-time purchase.",
              },
              {
                icon: DiscountIcon,
                copy: "Low margins from the first-time buyer discounts",
              },
              {
                icon: SpeedIcon,
                copy: "Slow payback on acquisition costs",
              },
            ]}
          />
        </div>
      </div>
      <div
        className="py-100"
        id="DiscoverHowMightWe"
        style={{ backgroundImage: "linear-gradient(white, #E0F7F6)" }}
      >
        <div className="gridContainer">
          <div className="divBlock1"></div>
          <div className="divBlock10">
            <p className=" mb-30">
              From the research above, we get to know that Wunderkind has a
              unique advantage in helping eCommerce brands deliver
              personalization throughout the customer journey, this leads to the
              design problem👇
            </p>
            <h2 style={{ color: "#189B83" }}>
              How might we make recommendation user friendly and help brand
              connect with customers in a non-intrusive, context-aware way?
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
