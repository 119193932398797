import medschoolheadshot from "../images/home-page/medicalHomepage.png";
import emailheadshot from "../images/home-page/EmailDesignHeadshot.png";


const projectsContent = [

  {
    id: "1",
    params: "emaildesigns",
    image: emailheadshot,
    copy: "Personalize marketing outreach at scale with behavioral targeting",
    year: "2022",
  },

  {
    id: "2",
    params: "medicalschool",
    image: medschoolheadshot,
    copy: "        Streamlining clinical rotation documentation for medical students    ",

    year: "2021",
  },
  // {
  //   id: "3",
  //   params: "wunderkind",
  //   image: wdknheadshot,
  //   copy: "Wunderkind Website",
  //   subhead1: (
  //     <div>
  //       From Engagement to Conversion:<br></br> Optimizing Content Experience
  //       for B2B Sales
  //     </div>
  //   ),
  //   year: "2022",
  // },
];

export default projectsContent;
