import React from "react";

import { Link } from "react-router-dom";
import projectsContent from "../../content/ProjectsContent";
import videoContent from "../../content/videoContent";

import classes from "./Footer.module.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";

const ProjectFooter = ({ idx }) => {
  const currentIdx = idx;
  const nextIdx = parseInt(currentIdx) + 1;
  console.log(nextIdx);
  const [linkColor, setLinkColor] = useState("#000"); // Default link color is white
  const isLastProject =
    nextIdx === projectsContent.length + videoContent.length;
  let project;
  if (currentIdx == 0) {
    // If the current index is 0, get the first project from projectsContent
    project = projectsContent[0];
  } else if (isLastProject) {
    // If the next index is equal to the length of projectsContent, get it from videoContent
    // Assuming you have a videoContent array

    project = videoContent[0]; // You may need to adjust this based on your actual data structure
  } else {
    // Otherwise, get the next project from projectsContent

    project = projectsContent[nextIdx - 1];
  }

  // Now, you can use the 'project' variable as before
  const linkTo = project ? `/${project.params}` : null;
  const firstProjectLink = `/${videoContent[0].params}`;

  // useEffect(() => {
  //   const conditions =
  //     location.pathname === "/emaildesigns" ||
  //     location.pathname === "/wunderkind";
  //   if (conditions) {
  //     setLinkColor("#000000"); // Set link color to black
  //   } else {
  //     setLinkColor("#FFFFFF"); // Set link color to white (default color scheme)
  //   }
  // }, [location.pathname]);

  return (
    <footer className={`${classes.footer} footer`}>
      <div className={`classes["linkNextContainer" ] mb-100`}>
        {/* <h4 className={`${classes.linkNext}`} >
          <Link style={{ color: linkColor }} to={`/${project.params}`}>View Next Project</Link> &#10145;&#65039;
        </h4> */}
        <h3 className={`${classes.linkNext}`}>
          {!isLastProject && (
            <Link style={{ color: linkColor }} to={linkTo}>
              View next project
            </Link>
          )}
          {isLastProject && (
            <Link style={{ color: linkColor }} to={firstProjectLink}>
              View next project
            </Link>
          )}
          &nbsp; &#10145;&#65039;
        </h3>
      </div>
      <Footer />
    </footer>
  );
};

export default ProjectFooter;
