import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import projectsContent from "../../content/ProjectsContent";
import classes from "./Projects.module.css";
import videoProjects from "../../content/videoContent";
import { motion } from "framer-motion"; // Import motion from framer-motion
import { useInView } from "react-intersection-observer";

const Projects = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Set the fadeIn state to true after a delay of 3 seconds
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 2500);

    // Clean up the timer to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const images = document.querySelectorAll("img");

    images.forEach((img) => {
      img.addEventListener("load", function () {
        img.classList.add("loaded");
      });
    });
  }, []);

  useEffect(() => {
    const videos = document.querySelectorAll("video");
    console.log(videos);

    videos.forEach((video) => {
      video.addEventListener("loadeddata", function () {
        console.log("Video loaded:", video);
        video.classList.add("loaded");
      });
    });

    // Clean up event listeners when the component unmounts
    return () => {
      videos.forEach((video) => {
        video.removeEventListener("loadeddata", function () {
          video.classList.add("loaded");
        });
      });
    };
  }, []);

  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.3, // Change the threshold value (0.5 means at least 50% of the element must be in view)
  });
  const animationVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const animation = inView ? "visible" : "hidden";

  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }} // Initial state (hidden and slightly below)
      animate={{ opacity: 1, y: 0 }} // Animate to visible and original position
      transition={{ duration: 0.6, delay: fadeIn ? 0 : 2.5 }} // Adjust duration and delay
      className={`${fadeIn ? "fade-in" : ""} ${classes.frames}`}
    >
      {" "}
      <div className={classes.projectContainer}>
        <div className={classes.videoGrid}>
          <div className="divBlock2"> </div>

          {videoProjects.map((content) => (
            <Link
              key={content.id}
              to={content.params}
              className={classes.videoframe}
            >
              <div className={classes.backgroundContainer}>
                <video autoPlay loop muted className={classes.backgroundVideo}>
                  <source src={content.image} type="video/mp4" />
                  <img
                    loading="lazy"
                    src={content.fallbackImage}
                    alt="Fallback"
                  />
                </video>
              </div>
              <div className={classes.content}>
                <div className={classes.headingContainer}>
                  <p className={classes.heading}>{content.copy}</p>
                  <p className={classes.year}>{content.year}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div ref={ref} className={classes.photoGrid}>
          <div className="divBlock2"> </div>
          {projectsContent.map((content) => {
            return (
              <Link
                key={content.id}
                to={content.params}
                className={classes.photoframe}
              >
                <motion.div
                  className={classes.backgroundContainer}
                  variants={animationVariants}
                  initial="hidden"
                  animate={animation}
                  transition={{ duration: 0.6 }}
                >
                  {" "}
                  <img
                    loading="lazy"
                    src={content.image}
                    alt="Project"
                    className={classes.backgroundImage}
                  />
                </motion.div>

                <div className={classes.content}>
                  <div className={classes.headingContainer}>
                    <p className={classes.heading}>{content.copy}</p>
                    <p className={classes.year}>{content.year}</p>
                  </div>
                  {/* <div className={classes.subheadContainer}>
                  <p className={classes.subhead}>{content.subhead1}</p>
                </div> */}

                  {/* <p className={classes.subhead}>{content.subhead2}</p> */}

                  {/* <h4 className={classes.projecttype}>{content.year}</h4> */}
                </div>
              </Link>
            );
          })}{" "}
        </div>
      </div>
    </motion.section>
  );
};

export default Projects;
