import Paragraph from "../../../shared/Sections/Paragraph";

import ImageFullWidth from "../../../shared/Sections/ImageFullWidth";
import Accordion from "../../../shared/Sections/Accordion";
import StudentQuote from "../../../images/MedicalSchool/Student quotes.png";
import Persona from "../../../images/MedicalSchool/Persona.png";
const ProblemDiscovery = () => {
  const items = [
    {
      title: '"Purely administrative"',

      bulletPoints: [
        "Students struggle to comprehend the necessity of filling out encounter logs, perceiving them as attendance requirements, leading to delayed and inaccurate completion.      ",
        "Students opt for the bare minimum to fulfill requirements. Optional notes are often ignored.",
      ],
    },
    {
      title: "Not intuitive and low level Of direction    ",
      bulletPoints: [
        "Students start tasks without clear guidance, struggling with navigation and data entry.",
        "Frequent medical term searches and repetitive demographic input underscore usability concerns.",
        "Information architecture doesn't align with students' mental models.",
      ],
    },
  ];
  return (
    <>
      <section class="content-block">
        <Paragraph
          title="02"
          classesH1="mb-50"
          subTitle="Problem discovery"
          subTitle2="Understanding the different perspectives"
          copy={
            <>
              <p>
                Why is it that, even when the data entered is simple, accuracy
                often falls short? To address this question, we conducted
                interviews with the Dean of Educational Technology and five
                medical students to gain insight into their perspectives.
                <br></br> <br></br>
                <strong>Research goal for the dean</strong>
                <br></br>
                Understand the role of patient logs in medical education and the
                expectations for students.
                <br></br> <br></br>
                <strong>Research goal for medical students' interviews</strong>
                <br></br> Understand students' emotions towards patient log
                documentation, their note-taking patterns, as well as needs and
                goals in general during clinical rotation.
              </p>
            </>
          }
        />
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="The main insight"
          copy="There is a disconnect in school director's and students' perceived value of the digital patient encounter documentation system"
        />
        <Accordion items={items} />

        <ImageFullWidth image={StudentQuote} />
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="Understanding the bigger pictures: Student's clinical rotation experience"
          copy="Based on the interviews, we were also able to craft a persona of the student during the clinical rotation. It helps us understand what students value, their needs, and goals, which plays a pivotal role in guiding us to find the right direction to solve the problem.          "
        />
        <ImageFullWidth image={Persona} />
      </section>
    </>
  );
};

export default ProblemDiscovery;
