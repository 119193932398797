import React from "react";
import DisplayTitle from "../../../../shared/Sections/DisplayTitle";
import Paragraph from "../../../../shared/Sections/Paragraph";
import Journey from "../../../../images/Wunderkind/Old journey vs new journey.png";
import Image from "../../../../shared/Sections/Image";
import Segment from "../../../../images/Wunderkind/User segments.png";
import LowIntent from "../../../../images/Wunderkind/Low intent.png";
import HighIntent from "../../../../images/Wunderkind/High intent.png";
import KeyResult from "../../../../shared/Sections/KeyNumbersHalf";
import Video from "../../../../shared/Sections/VideoFullWidth";
import LandingContinuity from "../../../../images/Wunderkind/landing page onsite continuity.mp4";
const Solution = () => {
  return (
    <div className="container">
      <div>
        <DisplayTitle
          overline="Solution"
          title="No more generic banners, more relevant, thoughtful content"
        />
        <Paragraph
          subTitle="Hypothesis"
          copy={
            <>
              Rather than having new, and more email modules, we could redesign
              and insert personalized sections into client’s existing marketing
              sequences, allowing for branding communication while providing
              additional value to customers. Make product show one step ahead
              could potentially increase email click through and conversion rate
              Encourage exploration and discovery by presenting a diverse range
              of products to gather more user preferences and data.
            </>
          }
        />
      </div>
      <div className="gridContainer">
        <div className="divBlock10">
          <Image image={Journey} />
        </div>
      </div>
      <div className="py-100">
        <DisplayTitle title="Visual design for emails" />
        <Paragraph
          subTitle="User segments"
          copy="After discussions with the product manager and engineers, we decided to segment users into three categories: insufficient data, high-intent, and low-intent. This segmentation allows us to craft different layout templates and provide a tailored design strategy for each user group."
        />
        <div className="gridContainer tp-50">
          <div className="divBlock5"></div>
          <div className="divBlock6">
            <Image image={Segment} />
          </div>
        </div>
        <div className=" tp-80 ">
          <Paragraph
            subTitle="Insufficient data or low intent customers"
            copy={
              <>
                Layout and content designed to{" "}
                <span className="highlight">
                  emphasize exploration and showcase diverse product offerings
                </span>
                to captivate user interest, featuring an{" "}
                <span className="highlight">
                  image-based grid with tight margins
                </span>{" "}
                for a visually appealing and engaging experience.
              </>
            }
          />
          <div className="gridContainer tp-50 mb-100">
            <div className="divBlock12">
              <Image image={LowIntent} />
            </div>
          </div>

          <Paragraph
            subTitle="High intent customers"
            copy={
              <>
                A more focused layout with fewer but highly relevant product
                recommendations to invoke urgency and drive immediate purchases.
              </>
            }
          />
          <div className="gridContainer tp-50">
            <div className="divBlock12">
              <Image image={HighIntent} />
            </div>
          </div>
        </div>
      </div>
      <div id="onsite continuity" className="mb-100">
        <DisplayTitle title="Landing page onsite continuity" />
        <Paragraph
          subTitle="Product page custom copy"
          copy={
            <>
              In addition to email design, we brainstormed several features for
              onsite continuity that clients can add to their product pages to
              enhance the user experience. These features focus on
              <span>
                user context, enabling user feedback, and accelerating product
                discovery.
              </span>
            </>
          }
        />

        <Video video={LandingContinuity} />
      </div>

      <div className="mb-100">
        <div className="mb-20">
          <DisplayTitle
            overline="Phase 1.5"
            title="Taking our design strategies to real-world application"
          />

          <Paragraph
            subTitle="Beta-testing with 9 client brands proves statistically significant results"
            copy="We beta-tested this solution with three brands and found that the results were statistically significant in impacting both the click-through rate and conversion rate."
          />
        </div>
        {/* <KeyResult
          blocks={[
            {
              title: "23% ↑",
              copy: ["repeat buyers"],
            },
            { title: "1.5x ", copy: ["ecommerce revenue"] },
            { title: "21% ↑", copy: ["Avg order value"] },
          ]}
        /> */}
      </div>
    </div>
  );
};

export default Solution;
