import React from "react";
import DisplayTitle from "../../../../shared/Sections/DisplayTitle";
import Paragraph from "../../../../shared/Sections/Paragraph";
import Image from "../../../../shared/Sections/Image";
import BusinessModel from "../../../../images/Wunderkind/Business model.png";
import KeyNumbersHalf from "../../../../shared/Sections/KeyNumbersHalf";
import Quotes from "../../../../images/Wunderkind/Quotes.png";
import Journey from "../../../../images/Wunderkind/Journey analysis.png";
import Prioritization from "../../../../images/Wunderkind/Prioritization.png";
import Carousel from "react-material-ui-carousel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
};
const anArrayOfNumbers = [
  <Image image={Journey} />,
  <Image image={Prioritization} />,
];
const Intro = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="container">
      <DisplayTitle
        overline="Section 2"
        title="Productizing recommendation modules in the self-service creative platform"
      />
      <div className="mb-50">
        <DisplayTitle
          overline="The problem"
          title="The 1 to 1 service model is not sustainable anymore as the company scales."
        />
        <div className="mb-50">
          <Paragraph
            subTitle="A new vision from leadership..."
            copy={
              <>
                Wunderkind was transitioning from a startup to medium sized
                company. As the company scaled to 700 clients, the team was
                overloaded due to scalability issue. As a result, revenue growth
                has slowed down and leadership decided to transition business
                model from white-glove service to providing different services
                based on company size and monthly recurring revenue.
              </>
            }
          />
        </div>
        <div className="px-20">
          <Image image={BusinessModel} />
        </div>
      </div>
      <div>
        <div className="mb-20">
          <DisplayTitle overline="Research" />
          <Paragraph
            subTitle="What clients and internal users are saying"
            copy="During our discovery, we reviewed client exit feedback and interviewed internal stakeholders. I collaborated closely with a researcher and our product manager. I wanted to understand our users’ goals and desired outcomes."
          />
        </div>
        <div className="mb-50">
          <KeyNumbersHalf
            blocks={[
              {
                title: "12",
                copy: ["interviews"],
              },
              { title: "3 ", copy: ["workshops"] },
              { title: "100+", copy: ["datapoints analyzed"] },
            ]}
          />
        </div>
        <div style={{ maxWidth: "1440px" }}>
          <Image image={Quotes} />
        </div>
      </div>

      <div>
        <Paragraph />
        <div className="flex" style={{ gap: "20px" }}>
          <Button onClick={handleOpen}>
            <Image image={Journey} />
          </Button>

          <Button onClick={handleOpen}>
            <Image image={Prioritization} />
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Carousel
                NextIcon={<KeyboardArrowRightIcon />}
                PrevIcon={<KeyboardArrowLeftIcon />}
                navButtonsAlwaysVisible
                IndicatorIcon={anArrayOfNumbers}
                indicatorIconButtonProps={{
                  style: {
                    width: "100px",
                    padding: "5px",
                  },
                }}
                autoPlay={false}
              >
                <Image image={Journey} />
                <Image image={Prioritization} />
              </Carousel>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Intro;
