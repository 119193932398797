import React, { useState, useEffect, useRef } from "react";
import AboutMe from "../components/Info/AboutMe";
import Experience from "../components/Info/Experience";
import IntroParagraph from "../components/Info/IntroParagraph";
import Contact from "../components/Info/Contact";
import Education from "../components/Info/Education";
import TechnicalSkills from "../components/Info/TechnicalSkills";
import Photography from "../components/Info/Photography";
import classes from "../components/Info/AboutMe.module.css";
import { motion } from "framer-motion";
import Awards from "../components/Info/Awards";

const Info = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Set the fadeIn state to true after a delay of 3 seconds
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 500);

    // Clean up the timer to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  const [isSticky, setIsSticky] = useState(false);
  const sidebarRef = useRef(null);
  const section1Ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const section1Rect = section1Ref.current.getBoundingClientRect();
      const sidebarRect = sidebarRef.current.getBoundingClientRect();
      console.log("section1top", section1Rect.top);
      const stickyOffset = -section1Rect.top + 200;
      console.log("section1bottom", section1Rect.bottom);


      if (section1Rect.top <= 100 && section1Rect.bottom > 435) {
        setIsSticky(true);
        sidebarRef.current.style.top = `${stickyOffset}px`;
      } else if (section1Rect.bottom < 435 && section1Rect.bottom > 0) {
        setIsSticky(true);
        sidebarRef.current.style.bottom = `0px`;
      } else {
        setIsSticky(false);
        sidebarRef.current.style.top = "auto";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className={`container mb-50 `}>
        <div ref={section1Ref} className="gridContainer infoPageContainer">
          <div className="divBlock3">
            {" "}
            <div
              ref={sidebarRef}
              className={`py-20 ${classes.contactContainer} ${
                isSticky ? classes.sticky : ""
              }`}
            >
              <Contact />
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0 }} // Initial state (hidden and slightly below)
            animate={{ opacity: 1 }} // Animate to visible and original position
            transition={{ duration: 0.6, delay: fadeIn ? 0 : 0.2 }} // Adjust duration and delay
            className={`divBlock9`}
          >
            <IntroParagraph />
            <Experience />
            <div className="info2Columns">
              <Education />
              <Awards />
            </div>
            <TechnicalSkills />
          </motion.div>
        </div>
        <div id="section2">
          <Photography />
        </div>
      </div>
    </>
  );
};

export default Info;
