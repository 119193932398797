import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classes from "./Section.module.css";
import { fa1 } from "@fortawesome/free-solid-svg-icons";

const Insights = (props) => {
  return (
    <>
      <div className={`${classes.insightContainer}`}>
        {props.insightTitle && <h4>{props.insightTitle}</h4>}
        {props.blocks.map((obj, index) => (
          <div className={classes.insightItem} key={index}>
            {/* <p>{`${index + 1}.`}</p> */}
            {obj.icon && <img style={{ width: "40px" }} src={obj.icon} />}
            {obj.itemTitle && <h3 className="mb-10">{obj.itemTitle}</h3>}
            {obj.copy && <p>{obj.copy}</p>}
          </div>
        ))}
      </div>
    </>
  );
};

export default Insights;
