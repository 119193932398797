import Paragraph from "../../../shared/Sections/Paragraph";

import existingSystem1 from "../../../images/MedicalSchool/Existing System 1.png";
import existingSystem2 from "../../../images/MedicalSchool/Existing System 2.png";

import Accordion from "../../../shared/Sections/Accordion";
import Insights2Column from "../../../shared/Sections/Insights2Column";
const Ideation = () => {
  const mainInsight = [
    {
      title: "Data disconnect",

      bulletPoints: [
        "EMRs ensure continuous patient history for informed decisions.      ",
        "  Medical students use isolated tools, hindering integration for a holistic patient journey.",
        "Exxat separates patient and procedure logs. WashU provides only a procedure log. Both results in a lack of patient history continuity.",
      ],
    },
    {
      title: "Training",

      bulletPoints: [
        "Professionals undergo extensive training for charting best practices.        ",
        "Note taking tools don't offer medical documentation specific trainings, however their onboarding is very user friendly.        ",
        "For the digital patient log, although step-by-step documents are provided to students, and online help pages exist, they primarily focus on guiding users through UI elements rather than providing students with content-related guidance.",
      ],
    },
    {
      title: "Documentation priorities      ",

      bulletPoints: [
        "EMRs emphasize clinical efficiency.        ",
        "Students prioritize comprehensive recording for educational insight.        ",
        "Student patient log deviates from industry-standard SOAP documentation and predominantly use menu-style selection boxes.",
      ],
    },
  ];
  return (
    <>
      <section class="content-block">
        <Paragraph title="03" subTitle="Existing systems" />
        <Paragraph
          subTitle2="Tapping into common practices"
          copy={
            <>
              <p>
                What if documentation can assist students in fulfilling their
                needs and objectives for clinical rotation? This is where its
                value can be communicated. If so, how can we bridge this
                disconnect?
                <br></br> <br></br> With this question in mind we conducted high
                level audit into the existing systems, aiming to understand the
                current documentation landscape in the medical field and the
                practices employed by medical students.
              </p>
              <br></br> <strong>Used by healthcare professionals</strong>
              <p>
                <li> Electronic medical record </li>
                <li>Charting best practices</li>
              </p>
              <br></br>
              <strong> Used by medical students</strong>
              <p>
                <li>
                  Where students actually record patient encounter notes (Google
                  Docs, Evernote, Notion)
                </li>
                <li>Patient Log (WashU, Exxat)</li>
              </p>
            </>
          }
        />
        <Insights2Column
          image1={existingSystem1}
          annotation1="Used by healthcare professionals  "
          image2={existingSystem2}
          annotation2="Patient log systems for medical students"
        />
      </section>

      <section class="content-block">
        <Paragraph
          subTitle2="The main insight"
          copy="Existing systems lack alignment with both student needs and professional practices."
        />
        <Accordion items={mainInsight} />
      </section>
    </>
  );
};
export default Ideation;
