import React, {useContext} from "react";
import Wunderkind from "../../components/Wunderkind/Wunderkind";
import AuthContext from "../../context/auth-context";
import Login from "../../components/Login/Login";
const WunderkindProject = () => {
  const ctx = useContext(AuthContext);

  return (
    <>
      {!ctx.isLoggedIn && <Login onLogin={ctx.onLogin} />}
      {ctx.isLoggedIn && <Wunderkind />}
    </>
  );
};

export default WunderkindProject;
