import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react";
import AuthContext from "../../context/auth-context";

import Input from "../../shared/Input/Input";
import classes from "./Login.module.css";

import lockImage from "../../images/lock-icon.png";

const passwordReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return {
      value: action.val,
      isValid: typeof action.val.trim() === "string",
    };
  }
  if (action.type === "INPUT_BLUR") {
    return {
      value: state.value,
      isValid: typeof state.value.trim() === "string",
    };
  }
  return { value: "", isValid: false };
};

const Login = () => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
    value: "",
    isValid: null,
  });
  const [passwordError, setPasswordError] = useState(false);

  const authCtx = useContext(AuthContext);

  const passwordInputRef = useRef();
  const { isValid: passwordIsValid } = passwordState;

  useEffect(() => {
    const identifier = setTimeout(() => {
      setFormIsValid(passwordIsValid);
    }, 500);
    return () => {
      clearTimeout(identifier);
    };
  }, [passwordIsValid]);

  const passwordChangeHandler = (event) => {
    dispatchPassword({ type: "USER_INPUT", val: event.target.value });
  };

  const validatePasswordHandler = () => {
    dispatchPassword({ type: "INPUT_BLUR" });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (formIsValid) {
      authCtx.onLogin(passwordState.value);
      console.log("Form submitted successfully.", passwordState.value);
      if (!authCtx.isLoggedIn) {
        setPasswordError(true);
      }
    } else {
      passwordInputRef.current.focus();
      console.log(
        "Form submission failed. Please fill out the form correctly."
      );
    }
  };

  return (
    <main className={classes.login}>
      <form onSubmit={submitHandler}>
        <img src={lockImage} alt="Lock Screen" />
        <h3>This project is under NDA</h3>
        <p className={`annotation ${classes.email}`}>
          Password can be found on resumé or email me at{" "}
          <a
            href="mailto:xiaoxuann.wu@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            xiaoxuann.wu@gmail.com
          </a>{" "}
          for password
        </p>
        <Input
          classes={classes.input}
          ref={passwordInputRef}
          id="password"
          label="Password"
          type="text"
          placeholder="Enter Password"
          isValid={passwordState.isValid}
          value={passwordState.value}
          onChange={passwordChangeHandler}
          onBlur={validatePasswordHandler}
        />
        {passwordError && (
          <p className={`annotation ${classes.invalid}`}>
            Password is incorrect
          </p>
        )}
        <div className={classes.actions}>
          <button type="submit" className={classes.btn}>
            Submit
          </button>
        </div>
      </form>
    </main>
  );
};

export default Login;
