import Paragraph from "../../../shared/Sections/Paragraph";
import ImageParagraph from "../../../shared/Sections/ImageParagraph";
import ImageFullWidth from "../../../shared/Sections/ImageFullWidth";
import VideoFullWidth from "../../../shared/Sections/VideoFullWidth";
import Inlinezone from "../../../images/email-designs/Onsite Annotation.mp4";
import Signup1 from "../../../images/email-designs/Onsite Sign Up 1.png";
import incentiveTest from "../../../images/email-designs/Incentives test.png";
import imageTest from "../../../images/email-designs/Image Test.png";
import multi from "../../../images/email-designs/Multi step.png";
import finalOnsite from "../../../images/email-designs/Onsite Final.png";
const OnsiteDesign = () => {
  return (
    <>
      <section class="content-block">
        <Paragraph
          title="04"
          subTitle="Onsite sign up modals"
          classesH1="mb-50"
          subTitle2="First round of implementation of behavior-triggered modals "
          copy="Following the implementation of our segmentation approach and exit-intent popup strategy, we observed a notable 9% increase in daily signups within the initial week. This indicates strong evidence of the effectiveness of our segmentation approach.  "
        />
        <ImageFullWidth image={Signup1} />
      </section>{" "}
      <section class="content-block">
        <Paragraph
          subTitle2="Continuous testing"
          copy="After the initial success, subsequently, we fine-tuned our messages and continued the optimization process through A/B testing. We did a total testing of 16 modals for the 4 segments. Here's an example of one we did for the high value items."
        />
        <Paragraph
          subTitle2="Incentive test"
          copy="We sought to determine which incentives would be most effective in encouraging user sign-ups, particularly for high-value furniture items, given our initial uncertainty about user behavior. After testing, we identified a clear winner – a combination of two incentives. This strategy outperformed other options in driving user sign-ups."
        />
        <ImageFullWidth image={incentiveTest} />
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="One step vs. multi steps"
          copy="In our A/B testing of one-step versus two-step sign-up processes, the former emerged as the winner.  This outcome suggests that customers likely prefer direct and immediate communication of incentives. 
        "
        />
        <ImageFullWidth image={multi} />
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="Image test"
          copy="We also tested the impact of visual elements by testing the inclusion of images within the sign-up process. The inclusion of an image with an interior designer emerged as the winning variation, possibly because this visual element instilled trust and professionalism, resonating well with users interested in design services. "
        />
        <ImageFullWidth image={imageTest} />
        <Paragraph
          subTitle2="Final modal design"
          copy="After conducting various tests, we have reached a conclusive finding regarding the most effective combination of incentives, visual elements, and the number of steps in the sign up modal for high value items.        "
        />
        <ImageFullWidth image={finalOnsite} />{" "}
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="Persistant inline zone"
          copy="We also experimented with a few other onsite experiences for sign ups, aiming to deliver a less intrusive way to remind users to sign up for the free premium service. "
        />
        <VideoFullWidth video={Inlinezone} />
      </section>
    </>
  );
};

export default OnsiteDesign;
