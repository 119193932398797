import { React, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";import classes from "../Info/AboutMe.module.css";
import ProfileImg from "../../images/ProfilePic.jpg";

const IntroParagraph = () => {
  useEffect(() => {
    const images = document.querySelectorAll("img");

    images.forEach((img) => {
      img.addEventListener("load", function () {
        img.classList.add("loaded");
      });
    });
  }, []);
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.3, // Change the threshold value (0.5 means at least 50% of the element must be in view)
  });
  const animationVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const animation = inView ? "visible" : "hidden";
  return (
    <div className="flex mb-50">
      <div className="flex flex-col pr-10  py-20">
        <h1 className="mb-10 displayMd">Hello I'm Xiao</h1>
        <p className=" mb-10 ">I'm so happy you're here!</p>
        <p className="">
          I’m a product designer based in New York with extensive experience in
          marketing and communication. Having a passion for creating art,
          serving my users well, and crafting amazing experiences, I find joy in
          tackling complex human challenges through design.
        </p>
      </div>
      <div className="imageContainer">
        <img
          src={ProfileImg}
          alt="Profile Picture"
          width="100%"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default IntroParagraph;
