import React from "react";
import DisplayTitle from "../../../shared/Sections/DisplayTitle";
import Card from "../../../shared/Sections/Card";
import Repeat from "../../../images/icons/Repeat.svg";
import Scalibiilty from "../../../images/icons/Scalibiilty.svg";

const Opportunities = () => {
  return (
    <div className="container">
      <DisplayTitle
        overline="Opportunities"
        title="Future vision of Wunderkind’s email marketing product"
      />
      <div className="gridContainer mb-100">
        <div className="divBlock6">
          <Card
            icon={Repeat}
            itemTitle="Repeat Purchases"
            copy="Most shoppers do not return after their initial purchase, leading to low margins from first-time discount offers. We aim to help brands increase repeat purchases and improve customer retention."
          />
        </div>
        <div className="divBlock6">
          <Card
            icon={Scalibiilty}
            itemTitle="Scalability"
            copy="As we grow from a startup to a medium-sized company with over 700 brands, the 1 to 1 service model becomes unsustainable. With the leadership's decision to split accounts into small and strategic segments, our team faces the challenge of developing a platform for autonomous workflows to support this new structure."
          />
        </div>
      </div>
    </div>
  );
};

export default Opportunities;
