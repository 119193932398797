import React from "react";
import VideoFullWidth from "../../../shared/Sections/VideoFullWidth";
import ImageFullWidth from "../../../shared/Sections/ImageFullWidth";
import Paragraph from "../../../shared/Sections/Paragraph";
import EncounterLogVideo from "../../../images/MedicalSchool/Adding New Patient Log.mp4";
import LearningObjectiveVideo from "../../../images/MedicalSchool/learning objective.mp4";
import EncounterLog from "../../../images/MedicalSchool/Encounter Log.png";
import FinalDesign from "../../../images/MedicalSchool/FinalDesign1.png";
const FinalPrototpes = () => {
  return (
    <>
      {" "}
      <section class="content-block">
        <Paragraph
          title="09"
          subTitle="Final design"
          classesH1="mb-50"
          subTitle2=" Documentation for clinical rotation education"
          copy="Incorporating all the prior research and iterations, I developed prototypes for key pages and flows, including patient information, encounter logs, learning objectives, and the associated navigation to demonstrate important interactions.
          "
        />
        <ImageFullWidth image={FinalDesign} />

        <Paragraph
          subTitle2="Comprehensive patient journey"
          copy=" Creating a structured documentation system for students to track patients over time, fostering a cohesive understanding of patient journeys."
        />
        <VideoFullWidth
          video={EncounterLogVideo}
          annotation="Creating a new patient log"
        />
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="Guided documentation"
          copy="Aligning documentation for education and clinical efficiency. Combining selection boxes for general input with free writing for narrative documentation in the medical SOAP format, with guiding questions designed to assist students."
        />
        <ImageFullWidth image={EncounterLog} />
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="Learning objective integration"
          copy='Establish a clear link between patient interactions and academic growth. For a more user-centered writing approach, the "requirement checklist" was renamed to "procedure learning objectives," and learning materials, including video explanations, were integrated for each procedure. Encounter logs were incorporated in the same section, allowing students to reference their previous practice sessions for a comprehensive understanding. '
        />
        <VideoFullWidth video={LearningObjectiveVideo} />
      </section>
    </>
  );
};

export default FinalPrototpes;
