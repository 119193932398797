import React from "react";
import classes from "./Section.module.css";
const Paragraph = (props) => {
  return (
      <div className="gridContainer">
        <div className={classes.divBlock4}>
          {props.subTitle && <h3>{props.subTitle}</h3>}
        </div>
        <div className="divBlock1"></div>
        <div className={classes.divBlock6}>
          {props.copy && <p>{props.copy}</p>}
        </div>
      </div>
  );
};

export default Paragraph;
