import Paragraph from "../../../shared/Sections/Paragraph";
import ImageParagraph from "../../../shared/Sections/ImageParagraph";
import ImageFullWidth from "../../../shared/Sections/ImageFullWidth";
import InvertedPyramid from "../../../images/email-designs/Inverted pyramid.png";
import HeroSection from "../../../images/email-designs/Hero section.png";
import Categories from "../../../images/email-designs/Categories.png";
import Banner from "../../../images/email-designs/Banner.png";
import Content1 from "../../../images/email-designs/Content 1.png";
import Content2 from "../../../images/email-designs/Content 2.png";
import TitleInsight from "../../../shared/Sections/TitleInsight";

const EmailDesign = () => {
  return (
    <>
      <section class="content-block">
        <Paragraph
          title="05"
          classesH1="mb-50"
          subTitle="Email design"
          subTitle2="Updating key components"
          copy=" The inverted pyramid places critical content at the top to immediately grab the recipient's attention and direct them to the CTA. "
        />
      </section>
      <section class="content-block">
        <ImageParagraph
          subTitle2="Hero layout"
          copy="The inverted pyramid places critical content at the top to immediately grab the recipient's attention and direct them to the CTA. "
          image={InvertedPyramid}
        />
        <ImageParagraph
          image={HeroSection}
          copy="It's also mobile-friendly, optimizing limited screen space by minimizing distractions and presenting essential information upfront."
        />
        <ImageParagraph
          subTitle2="Categories"
          copy="Organize the category list according to user interests. For first-time visitors, we segment it into furniture items and smaller home decor items. As users engage more with the website, this list can be updated dynamic to show categories tailored to each recipient.

        "
          image={Categories}
        />
        <ImageParagraph
          subTitle2="Banners"
          copy="In the update, we also refreshed OKL's banner design with two key goals in mind: upholding visual consistency with the brand by aligning colors, fonts, and image treatments with OKL's identity, and incorporating eye-catching graphics and relevant images to enhance the banner's message."
          image={Banner}
        />
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="Goal-oriented email content"
          copy="Crafting Tailored Messages and Layouts for Varied Segments.         Considering our distinct goals, we've implemented two content strategies in our email campaigns — informative approach and conversion-focused approach."
        />
        <TitleInsight
          subTitle="Inform, engage, and nurture audience in program offerings"
          blocks={[
            {
              itemTitle: "Hero",
              copy: "Captivate recipients with a prominent offer or message right at the email's outset, ensuring immediate engagement.",
            },
            {
              itemTitle: "Program details and intro",
              copy: " Provide a comprehensive introduction to the program, offering essential information and building recipient interest and trust.",
            },
            {
              itemTitle: "Social proof",
              copy: "Foster trust by showcasing past projects or positive experiences from reviews.",
            },
            {
              itemTitle: "Resources",
              copy: "At the end of the email, offer materials and additional information related to the program, promoting further engagement.",
            },
          ]}
        />
        <ImageFullWidth image={Content1} />
      </section>

      <section class="content-block">
        <TitleInsight
          subTitle="Prompt action and drive conversions for product interactions"
          blocks={[
            {
              itemTitle: "Dynamic product recommendations",
              copy: "Dynamic Product Recommendations:</strong> Emails feature products based on user browsing and cart activity, increasingrelevance.",
            },
            {
              itemTitle: "Personalized categories",
              copy: "Content aligns with recipient preferences, facilitating rediscovery of items.  ",
            },
            {
              itemTitle: "Category-based banners",
              copy: "Visuals and messaging tailored to category segmentation, enhancing personalization and engagement.",
            },
          ]}
        />
        <ImageFullWidth image={Content2} />
      </section>
    </>
  );
};

export default EmailDesign;
