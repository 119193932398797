import React from "react";

import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={`${classes.footer} footer`}>
      <div
        style={{ marginBottom: `30px` }}
        className={classes["divider"]}
      ></div>
      <div className={classes["footerMainContent"]}>
        <div className={classes["footerItemLeft"]}>
          <h3>Thanks for visiting!</h3>
          <div className={classes.copyrightContent}>
            <p className={classes["copyright"]}>
              Made with &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 -960 960 960"
                width="20"
              >
                <path d="M320-240 80-480l240-240 57 57-184 184 183 183-56 56Zm320 0-57-57 184-184-183-183 56-56 240 240-240 240Z" />
              </svg>
              &nbsp; by me.
            </p>
            <p className={classes["copyright"]}>
              Last updated: September 27, 2023
            </p>
          </div>
        </div>
        <div className={classes["footerLinkMobile"]}>
          <p>
            <a
              href="https://www.linkedin.com/in/xiaoxuan-wu-421775156/"
              target="_blank"
              rel="noreferrer"
              className={classes["link"]}
            >
              LinkedIn
            </a>
          </p>
          <p>
            <a
              href="mailto:xiaoxuann.wu@gmail.com"
              className={classes["link"]}
              target="_blank"
              rel="noreferrer"
            >
              Contact Me
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
