import DesktopFooter from "./DesktopFooter"
import MobileFooter from "./MobileFooter"
import React, { useState, useEffect } from 'react';

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    // Add an event listener to listen for screen size changes
    function handleResize() {
      setIsMobile(window.innerWidth <= 767);
    }

    // Attach the event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="Footer">
      {isMobile ? <MobileFooter /> : <DesktopFooter />}
    </div>
  );
}
 

export default Footer;
