import ImageFullWidth from "../../../shared/Sections/ImageFullWidth";
import Paragraph from "../../../shared/Sections/Paragraph";

import ComponentDesignSystem from "../../../images/MedicalSchool/New Component.png";

import TypeSetting from "../../../images/MedicalSchool/Typesetting.png";
import ImageParagraph from "../../../shared/Sections/ImageParagraph";
import StyleGuide from "../../../images/MedicalSchool/WashU Style Guide.png";

const VisualDesign = () => {
  return (
    <>
      {" "}
      <section class="content-block">
        <Paragraph title="08" subTitle="Visual design " classesH1={`mb-50`} />
        <ImageParagraph
          subTitle2="Design system"
          copy={
            <>
              In 2022, I revisited the initial visual design. My primary focus
              when considering visual design updates was to modernize the
              platform while maintaining a notable level of consistency with the
              school's open-source design guidelines. I reviewed the design
              guidelines and examined several of the school's publicly-facing
              websites.
              <br></br> <br></br>
              During this process, I discovered that while the school had some
              high-level brand guidelines, there was a lack of detailed
              documentation regarding the usage of fonts, colors, and components
              for web. This presented a great opportunity for me to practice
              adapting a brand guidelines for digital applications and to create
              a customized component systems.
            </>
          }
          image={StyleGuide}
        />
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="Visual considerations"
          copy="Considering the emphasis on text input, the visual design prioritizes simplicity and readability. Additionally, I wanted to choose a typography system that is well-suited for long-form content, ensuring an optimal reading experience for users."
        />
        <ImageFullWidth image={ComponentDesignSystem} />
        <ImageFullWidth image={TypeSetting} />
      </section>
     
    </>
  );
};

export default VisualDesign;
