import React from "react";
import classes from "../Info/AboutMe.module.css";

const ExperienceItem = (props) => {
  return (
    <li className={classes.experienceItemContainer}>
      <div className={classes.experienceTime}>
     
        <h3 className={`${classes.timeText} titleLg`}> {props.time}</h3>
      </div>
      <div>
        <h3 className="titleLg mb-5">{props.title}</h3>
        <h4 className="mb-5">{props.company}</h4>
        <p className="bodySm">{props.description}</p>
      </div>
    </li>
  );
};

export default ExperienceItem;
