import React from "react";

import classes from "./Section.module.css";

const Insights2Column = (props) => {
  return (
    <>
      <div className={classes.gridContainer}>
        <div className={`${classes.insightContainer} ${classes.divBlock6}`}>
          {props.image1 && (
            <div className={`${classes.imageContainer}`}>
              <img
                loading="lazy"
                src={props.image1}
                alt={props.alt}
                width="100%"
              />
            </div>
          )}{" "}
          {props.subTitle1 && <h4>{props.subTitle1}</h4>}
          <div>
            {props.annotation1 && (
              <span className={classes.annotation}>{props.annotation1}</span>
            )}{" "}
          </div>
          {props.blocks1 &&
            props.blocks1.map((props, index) => (
              <div className={classes.iconContentContainer} key={index}>
                {props.icon && (
                  <div style={{ width: "25px" }}>
                    {" "}
                    <img src={props.icon} />{" "}
                  </div>
                )}
                <div className={classes.insightItem}>
                  {props.itemTitle && (
                    <h4 className="mb-10">{props.itemTitle}</h4>
                  )}
                  {props.copy && <p className="bodySm">{props.copy}</p>}{" "}
                </div>
              </div>
            ))}
        </div>

        <div className={`${classes.insightContainer} ${classes.divBlock6}`}>
          {props.image2 && (
            <div className={`${classes.imageContainer}`}>
              <img
                loading="lazy"
                src={props.image2}
                alt={props.alt}
                width="100%"
              />
            </div>
          )}{" "}
          {props.subTitle2 && <h4>{props.subTitle2}</h4>}{" "}
          <div>
            {props.annotation2 && (
              <span className={classes.annotation}>{props.annotation2}</span>
            )}{" "}
          </div>{" "}
          <div>
            {props.copy1 && (
              <span className={classes.annotation}>{props.copy}</span>
            )}{" "}
          </div>
          {props.blocks2 &&
            props.blocks2.map((props, index) => (
              <div className={classes.iconContentContainer} key={index}>
              {props.icon && (
                  <div style={{ width: "25px" }}>
                    {" "}
                    <img src={props.icon} />{" "}
                  </div>
                )}
                <div className={classes.insightItem}>
                  {props.itemTitle && (
                    <h4 className="mb-10">{props.itemTitle}</h4>
                  )}
                  {props.copy && <p className="bodySm">{props.copy}</p>}{" "}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Insights2Column;
