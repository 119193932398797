import React from "react";
import Paragraph from "../../../shared/Sections/Paragraph";
import Image from "../../../shared/Sections/Image";
import Timeline from "../../../images/Wunderkind/My Timeline at Wunderkind.png";
import ServiceModel from "../../../images/Wunderkind/Wunderkind platform service mode.png";
import DisplayTitle from "../../../shared/Sections/DisplayTitle";
import CurrentFlow from "../../../images/Wunderkind/Current flow.png";
import CurrentService from "../../../images/Wunderkind/Service model.png";
const Context = () => {
  return (
    <div className="py-20 container">
      <Paragraph
        subTitle="About Wunderkind"
        copy="Wunderkind is a growth marketing tech company that helps ecommerce brands create personalized email and SMS campaigns.
      "
      />
      <div className="gridContainer">
        <div className="divBlock4"></div>
        <div className="divBlock6">
          {" "}
          <Image image={ServiceModel} />
        </div>
      </div>
      <Paragraph
        subTitle="My timeline and responsibilities"
        copy="My first year was mostly working on client facing projects to provide design service on triggered email.
      After one year at Wunderkind, I advanced to a senior role, further expanding my responsibilities with focus on product development. My focus was on Wunderkind’s email product. I was the design lead for two projects and collaborated closely with product managers, email engineers, and customer success. "
      />
      <div className="gridContainer tp-30 mb-50">
        <div className="divBlock1"></div>

        <div className="divBlock10">
          <Image image={Timeline} />
        </div>
      </div>
      <div className="py-20">
        <div className="mb-20">
          <DisplayTitle
            overline="challenges"
            title="Current state of Wunderkind's email product"
          />
        </div>

        <div className="gridContainer mb-50">
          <div className="divBlock1"></div>
          <div className="divBlock4">
            <h3 className="mb-10">Triggered emails</h3>
            <p>
              Current product focuses on triggered emails based on user
              abandonment behavior, serving them reminders about unfinished
              actions to convert
              <span className="highlight">first time buyers.</span>
            </p>
          </div>
          <div className="divBlock1"></div>

          <div className="divBlock5">
            <Image image={CurrentFlow} />
          </div>
        </div>
        <div className="gridContainer">
          <div className="divBlock2"></div>
          <div className="divBlock10">
            <h3>
              Current service models involves at least&nbsp;
              <span className="highlight">6 parties</span> and takes&nbsp;
              <span className="highlight">4+ weeks</span> to onboard a new
              client
            </h3>
          </div>{" "}
          <div className="divBlock2"></div>
          <div className="divBlock8">
            <Image image={CurrentService} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Context;
