import Paragraph from "../../../shared/Sections/Paragraph";

import Insights2Column from "../../../shared/Sections/Insights2Column";

import DashboardBefore from "../../../images/MedicalSchool/Dashboard - Before.png";
import DashboardAfter from "../../../images/MedicalSchool/Dashboard - After.png";
import OnboardingBefore from "../../../images/MedicalSchool/Onboarding - Before.png";
import OnboardingAfter from "../../../images/MedicalSchool/Onboarding - After.png";
import DocumentationBefore from "../../../images/MedicalSchool/Documentation - Before.png";
import DocumentationAfter from "../../../images/MedicalSchool/Documentation - After.png";
import LearningIntegration from "../../../images/MedicalSchool/Learning Objective Integration.png";
import Reflection from "../../../images/MedicalSchool/Reflection.png";

const WireframeSection = () => {
  return (
    <>
      {" "}
      <section class="content-block">
        <Paragraph
          title="06"
          classesH1="mb-50"
          subTitle="Layout and content exploration"
          subTitle2="Informative and intuitive"
          copy="We explored several layout and content concepts on onboarding, documentation, and navigation. Aligning with the overarching project goal, we strive to create designs that are both informative (providing value) and intuitive (easy to learn). I co-facilitated user testing with two medical students, from which we gathered valuable insights that informed our final design decisions.
          "
        />
        <Paragraph
          subTitle2="Onboarding: Annotation vs. examples"
          copy={
            <>
              The first iteration (left) was an attempt to offer in-app
              onboarding with annotations that illustrate why and how to fill
              out the log. However, annotation-based onboarding still made
              students feel as though it was merely explaining the UI and
              rubric, assuming they already knew what to input for charting.
              <br></br>
              <br></br>
              Taking this feedback into account, we explored example-based
              onboarding, which ultimately proved to be more valuable as it
              offered students more practical information.
            </>
          }
        />
        <Insights2Column
          image1={OnboardingBefore}
          blocks1={[
            {
              copy: "Annotation-based onboarding lacked contextual clarity, leaving students uncertain about accurately reporting subjective, objective, etc.           ",
            },
          ]}
          image2={OnboardingAfter}
          blocks2={[
            {
              copy: "    Shifted to examples and guiding questions to offer engaging and context-rich onboarding.    ",
            },
          ]}
        />
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="Documentation: menus vs. free writing"
          copy="Menus and selections guide input but limit interaction to clicking, which may not foster effective learning. We've decided to combine both: use selections for efficiency with general info like location and settings, and employ open-ended questions with prompts for patient-specific data."
        />
        <Insights2Column
          image1={DocumentationBefore}
          blocks1={[
            {
              copy: "Users seek open and flexible documentation methods.            ",
            },
            {
              copy: "Hierarchical, menu-driven navigation impedes quick engagement with documentation.          ",
            },
          ]}
          image2={DocumentationAfter}
          blocks2={[
            {
              copy: "  Integrate narrative documentation with selection boxes for an organic process.            ",
            },
            {
              copy: " Replace menu-based selection with a free-writing format to enhance the experience.            ",
            },
          ]}
        />
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2=" Simplifying navigation hierarchy  "
          copy="We went through several iterations of the navigation layout, focusing on the navigation bar, patient list, encounter list, and requirement checklist. Ideally, students should access these items daily. Given the high usage, it should offer quick and easy access.
        "
        />
        <Insights2Column
          image1={DashboardBefore}
          blocks1={[
            {
              copy: " Identified that the user flow contained unnecessary layers,leading to complexity in accessing specific information.           ",
            },
          ]}
          image2={DashboardAfter}
          blocks2={[
            {
              copy: "     Streamlined the dashboard and documentation design to offer direct and easy access to critical information to enhance user efficiency and task management.  ",
            },
          ]}
        />
      </section>
      <section class="content-block ">
        <Paragraph
          subTitle2="Space for personal growth"
          copy={
            <>
              During user testing, we gathered new insights indicating that the
              data disconnect extends beyond just patient history; it also
              involves a disconnect to students' goals and learning objectives.
              <br></br>
              <br></br>
              The procedure logs, instead of being just a requirement checklist,
              offer an opportunity to facilitate student learning. Additionally,
              incorporating a reflection log may help foster a sense of purpose
              and a more holistic appreciation for learning.
            </>
          }
        />
        <Insights2Column
          image1={LearningIntegration}
          blocks1={[
            {
              copy: "Establish a clear connection between patient interactions and academic growth.            ",
            },
            {
              copy: "Enhance understanding of progress and educational journey            ",
            },
            {
              copy: "Integrate written explanations with videos to provide students with a comprehensive understanding of the procedures.            ",
            },
          ]}
          image2={Reflection}
          blocks2={[
            {
              copy: " Introduce dedicated section for personal insights and growth reflections            ",
            },
            {
              copy: " Foster emotional engagement and meaningful connection to patient encounters            ",
            },
            {
              copy: " Cultivate a sense of purpose and holistic learning appreciation            ",
            },
          ]}
        />
      </section>
    </>
  );
};

export default WireframeSection;
