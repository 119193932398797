import Paragraph from "../../../shared/Sections/Paragraph";
import Accordion from "../../../shared/Sections/Accordion";
import TitleInsight from "../../../shared/Sections/TitleInsight";
const ProblemDiscovery = () => {
  return (
    <>
      {" "}
      <section className="content-block">
        <Paragraph
          title="09"
          subTitle="Reflection"
          classesH1="mb-50"
          subTitle2="Feedback and metrics to consider"
          copy="This solution has received positive feedback from both our professors and program directors. As this project is conceptual class project, there is no quantitative measurement. However, looking ahead, I think the following metrics that can be used to assess the solution's effectiveness.
        "
        />
        <TitleInsight
          blocks={[
            {
              itemTitle: "Daily engagement",
              copy: "Increased frequency of student visits to the platform, indicating improved user engagement and ongoing use for documentation.",
            },
            {
              itemTitle: "Data accuracy",
              copy: " Higher accuracy of recorded patient encounters compared to previous sporadic entries, showcasing the positive impact on documentation quality.",
            },
            {
              itemTitle: "Student satisfaction survey",
              copy: "Conducted surveys to gauge students' perception of the tool's value in their education and their overall satisfaction.",
            },
          ]}
        />
      </section>
      <section className="content-block">
        <Paragraph
          subTitle2="What I learned"
          copy={
            <>
              <p>
                This marks my first end-to-end UX project. Getting the chance to
                dive in, connect with users, create user experiences, and design
                interfaces from the ground up was fascinating. As I put
                everything into practice, I had some eye-opening moments about
                how user-centric design can make a real difference in education.
                This really motivated me to further explore this fascinating
                field.
              </p>
            </>
          }
        />
        <Accordion
          items={[
            {
              title: "Challenge: End users vs. purchaser",
              content:
                "In the situation where end users and purchasers aren't the same, it was challenging to address the varying expectations of school directors and students. Understanding the goals and needs of both perspectives during user research and finding the balance in the solution is the key.",
            },
            {
              title: "Aesthetic-usability effect",
              content:
                "During this project, I had the opportunity to apply several UX design theories I learned in class. One of the most noticeable theories was the aesthetic-usability effect. It became evident that students often assumed the software was exclusively for administrative purposes due to its poor UI design. ",
            },
            {
              title: "Designing for adoption in educational software",
              content:
                "Generally, people have an aversion to assignments. It's clear that users resent being compelled to perform specific actions. Therefore, successful design for adoption hinges on consistently delivering user value.",
            },
            {
              title: "Emotional design",
              content:
                "I discovered that to achieve great UX, it is essential to cater to users' diverse emotional needs and desires on multiple levels. Creating a fulfilling user experience goes beyond just meeting functional requirements.",
            },
          ]}
        />
      </section>
    </>
  );
};

export default ProblemDiscovery;
