import Paragraph from "../../../shared/Sections/Paragraph";
import ImageParagraph from "../../../shared/Sections/ImageParagraph";
import ImageFullWidth from "../../../shared/Sections/ImageFullWidth";
import TitleInsight from "../../../shared/Sections/TitleInsight";
import Insights from "../../../shared/Sections/Insights";
import ProductType from "../../../images/email-designs/Product type.png";
import Persona from "../../../images/email-designs/Persona.png";
import CurrentOnsite from "../../../images/email-designs/OKL Current Site.png";
import CurrentEmail from "../../../images/email-designs/Current Email.png";
import { fa1, fa2, fa3, fa4 } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Insights2Column from "../../../shared/Sections/Insights2Column";
import Accordion from "../../../shared/Sections/Accordion";

const DefineDiscover = () => {
  const fourIcon = <FontAwesomeIcon icon={fa4} size="lg" />;
  const oneIcon = <FontAwesomeIcon icon={fa1} size="lg" />;
  const twoIcon = <FontAwesomeIcon icon={fa2} size="lg" />;
  const threeIcon = <FontAwesomeIcon icon={fa3} size="lg" />;

  return (
    <>
      <section className="content-block">
        <Paragraph
          title="02"
          subTitle="Opportunity discovery"
          classesH1="mb-50"
          subTitle2="Understanding the brand"
          copy="I started the design process with an onsite audit to better understand OKL's brand and where they currently stand. One important thing I discovered is that they have distinct product types and shopper personas.

"
        />
        <Accordion
          items={[
            {
              title:
                "Product categories: high-value furniture vs. affordable home decor",
              content:
                "OKL's website caters to two primary product categories, encompass an extensive price range, spanning from budget-friendly options priced at $10+ to high-end, luxury items reaching $9000+. These categories attract different types of shoppers who have distinct preferences and shopping behaviors.",
            },
            {
              title: "Shopper persona: commercial vs. personal",
              bulletPoints: [
                "Commercial shoppers: primarily focused on professional projects, prioritizing quality and customization.",
                "  Personal shoppers: seek affordable furniture and decors for personal spaces, emphasizing affordability and style, and they often look for deals and promotions to enhance their living spaces.    ",
              ],
            },
          ]}
        />
        <Insights2Column
          image1={ProductType}
          annotation1="Product types"
          image2={Persona}
          annotation2="Persona"
        />
      </section>
      <section className="content-block">
        <Paragraph
          subTitle2="Auditing current design owned by Wunderkind"
          copy="The current sign-up and email design have been producing suboptimal open and click-through rates. We conducted a high-level audit, examining behavior triggers and messaging."
        />
        <Paragraph
          subTitle2="Onsite: Uniform sign-up messaging"
          copy="OKL currently employs a uniform sign-up messaging, offering a 20% discount and relying on behavior triggers such as entrance, exit, PDP, and timed. This uniform approach falls short in accommodating the unique needs and motivations of our diverse user segments. Consequently, it may result in missed email conversion opportunities, as users' expectations and preferences are not effectively addressed."
        />
        <ImageFullWidth image={CurrentOnsite} />
      </section>{" "}
      <section className="content-block">
        <Paragraph
          subTitle2="Email: Generic blasts tend to end up in the trash.   "
          copy="The current email design is yielding suboptimal open and click-through rates. In an era where personalization is expected, generic content is failing to engage our audience effectively."
        />
        <div>
          <ImageFullWidth image={CurrentEmail} />
          <Insights2Column
            blocks1={[
              {
                icon: oneIcon,
                itemTitle: "Hero copy placement and formatting",
                copy: "Need improvement to enhance text clarity, make the message more prominent and scannable, and create an attention-grabbing CTA.",
              },
              {
                icon: twoIcon,
                itemTitle: "Generic brand story introduction",
                copy: "The generic brand story introduction misses the opportunity to address users' specific needs and fails to engage users from the outset, limiting the email's impact.",
              },
            ]}
            blocks2={[
              {
                icon: threeIcon,
                itemTitle: "Limited category options and space utilization ",
                copy: "With only three category options, there's untapped potential for optimizing space utilization to offer a broader range of choices and enhance user engagement             ",
              },
              {
                icon: fourIcon,
                itemTitle: "Unrelated promotions",
                copy: "Due to a lack of segmentation, promotions like a design consultation banner are also sent to users who have abandoned a $10 home decor item. This indiscriminate approach can lead to reduced engagement. ",
              },
            ]}
          />
        </div>
      </section>
      <section className="content-block">
        <Paragraph
          subTitle2="Design direction definition"
          copy="After conducting a high-level review of the website and examining the current modules ran by Wunderkind, I identified the following opportunities to enhance the customer experience."
        />{" "}
        <TitleInsight
          blocks={[
            {
              itemTitle: "Meeting users where they are through segmentation",
              copy: "Personalize content and interactions tailored to individual needs and preferences.",
            },

            {
              itemTitle: "Conversion rate optimization",
              copy: " Enhancing user engagement through A/B Testing",
            },
            {
              itemTitle: "Refreshing visual design",
              copy: "Update visual presentation to reflect a sophisticated and luxury brand image to align with OKL's brand identity.",
            },
          ]}
        />
      </section>{" "}
    </>
  );
};

export default DefineDiscover;
