import React, { useState, useEffect, useRef } from 'react';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import classes from "../Info/AboutMe.module.css";
import { ReactComponent as LinkedinIcon } from "../../images/icons/Linkedin.svg";
import { ReactComponent as EmailIcon } from "../../images/icons/Email.svg";

const Contact = () => {

  return (
 
      <div className={classes.contactListContainer}>
        <p className="titleLg">Contact</p>
        <span className={classes.contactItem}>
          <EmailIcon />
          xiaoxuann.wu@gmail.com
        </span>
        <span className={classes.contactItem}>
          <LinkedinIcon />
          <a
            href="https://www.linkedin.com/in/xiaoxuan-wu-421775156/"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <p className="bodySm">Professional profile </p>
          </a>
        </span>
        <p className="bodySm">
          Resume and earlier works available upon request. 
        </p>
      </div>
  
  );
};

export default Contact;
