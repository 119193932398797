import Paragraph from "../../../shared/Sections/Paragraph";
import ImageFullWidth from "../../../shared/Sections/ImageFullWidth";
import everyplateImage from "../../../images/email-designs/Everyplate.png";
import Accordion from "../../../shared/Sections/Accordion";
import forever21 from "../../../images/email-designs/Forever 21.png";
import KeyResult from "../../../shared/Sections/KeyResultFullWidth";
const Other = () => {
  return (
    <>
      <section class="content-block">
        <Paragraph
          title="06"
          subTitle="Snapshots of additional client works"
          classesH1="mb-50"
          subTitle2="Adapting to diverse customer needs"
          copy={
            <>
              For brands in different industries, the design strategies for
              sign-up and abandonment emails vary, reflecting the distinct
              customer needs and behaviors. As a designer, I have had the
              opportunity to contribute to a range of projects, each tailored to
              cater to specific target audiences. Here are some snapshots of my
              other projects following a similar approach.
            </>
          }
        />{" "}
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="Everyplate: Food subscription"
          copy="
                  Food subscription users seek convenient, personalized, and
                  nutritious meals that save them time and effort. They value
                  quality ingredients, customization options, affordability, and
                  eco-friendly practices for a sustainable dining experience. To
                  meet these needs, the content and design strategy focuses on
                  emphasizing four key pillars:
             "
        />
        <Accordion
          items={[
            {
              title: "Urgency",
              content:
                "Highlight limited-time offers to encourage immediate action.              ",
            },
            {
              title: "Visual appeal ",
              content:
                "Use appetizing food imagery to evoke cravings and entice subscribers to complete their orders.          ",
            },
            {
              title: "Convenience",
              content:
                "Highlight the time-saving aspect of their service through easy-to-use platforms, streamlined meal selection, and flexible delivery options.            ",
            },
            {
              title: "Meal customization ",
              content:
                "Showcase the range of dietary choices, portion sizes, and ingredient preferences available, allowing customers to personalize their meals according to their unique tastes and requirements.          ",
            },
          ]}
        />
        <KeyResult
          blocks={[
            {
              title: "3.4x",
              copy: ["increase in revenue in the first month"],
            },
            {
              title: "3x",
              copy: ["increase in triggered email conversions in first month"],
            },
            {
              title: "7%",
              copy: ["of total digital revenue driven by Wunderkind"],
            },
          ]}
        />
        <ImageFullWidth image={everyplateImage} />
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="Forever 21: Fashion"
          copy=" Forever 21 customers seek trendy and affordable fashion pieces
                  that allow them to express their personal style. They value a
                  wide selection of clothing and accessories, staying up-to-date
                  with the latest fashion trends. The abandonment email design
                  focus on:"
        />
        <Accordion
          items={[
            {
              title: "Exclusive discounts",
              copy: "Offer discounts exclusively for abandoned cart items, motivating customers to complete their purchases.",
            },
            {
              title: "Seasonality",
              copy: "Highlight seasonal collections, limited-edition releases, and current fashion trends to create a sense of relevance and exclusivity, prompting subscribers to revisit and make a purchase.          ",
            },
            {
              title: "Visual impact",
              copy: "Showcase the latest fashion trends and create visually captivating emails that capture subscribers' attention and pique their interest.            ",
            },
            {
              title: "Styling inspiration  ",
              copy: "Provide outfit ideas and suggest similar and complementary items to help subscribers visualize how the products can be styled, encouraging them to revisit their abandoned shopping carts.          ",
            },
          ]}
        />
        <KeyResult
          blocks={[
            {
              title: "9.4x ",
              copy: ["increase in triggered email revenue "],
            },
            {
              title: "13x",
              copy: ["increase in traffic from abandonment email "],
            },
            {
              title: "7.8%",
              copy: ["of total digital revenue driven by Wunderkind"],
            },
          ]}
        />
        <ImageFullWidth image={forever21} />
      </section>
    </>
  );
};

export default Other;
