import React from "react";

const KeyNumbersHalf = (props) => {
  return (
    <div className="gridContainer">
      <div className="divBlock5"></div>
      {props.blocks.map((obj, index) => (
        <div className="divBlock2" key={index}>
          <div style={{ width: "90%" }}>
            <h3 style={{ fontSize: "40px" }}>{obj.title}</h3>
            {obj.copy.map((name, idx) => (
              <p className="bodySm" key={idx}>
                {name}
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default KeyNumbersHalf;
