import React from "react";
import classes from "./Section.module.css";
const KeyResultFullWidth = (props) => {
  return (
    <section className={`${classes.copy} ${props.classes}`}>
      <div className={classes.gridContainer}>
        {props.blocks.map((obj, index) => (
          <div className="divBlock3" key={index}>
            <div style={{ width: "80%" }}>
              <h3 style={{ fontSize: "40px" }}>{obj.title}</h3>
              {obj.copy.map((name, idx) => (
                <p className="bodySm" key={idx}>
                  {name}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default KeyResultFullWidth;
