import React, { useState, useEffect } from "react";

const AuthContext = React.createContext({
  isLoggedIn: null,
  onLogin: () => {},
});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedUserLoggedInInformation = sessionStorage.getItem("isLoggedIn");

    if (storedUserLoggedInInformation === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  const checkLogin = (password) => {
    const passwordKey = process.env.REACT_APP_PASSWORD;
    return password === passwordKey;
  };

  const loginHandler = (password) => {
    if (checkLogin(password)) {
      sessionStorage.setItem("isLoggedIn", "true");
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn: isLoggedIn, onLogin: loginHandler }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
