import React from "react";
import classes from "./Section.module.css";

const VideoFullWidth = (props) => {
  return (
    <section className={` ${props.classes}`}>
      <div
        className={`  ${classes.imageAnnotationContainer}`}
        style={{ maxWidth: "1440px" }}
      >
        <div>
          <video
            loading="lazy"
            className={props.videoClass}
            autoPlay
            muted
            loop
            width="100%"
          >
            <source src={props.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* Fallback image */}
          <noscript>
            <img src={props.fallbackImage} alt="Fallback" />
          </noscript>
        </div>
        <div>
          {props.annotation && (
            <span className={classes.annotation}>{props.annotation}</span>
          )}{" "}
        </div>
      </div>
    </section>
  );
};

export default VideoFullWidth;
