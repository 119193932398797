import React from "react";
import CaseStudyIntro from "../../../shared/Sections/Intro";
import Paragraph from "../../../shared/Sections/Paragraph";
import KeyResult from "../../../shared/Sections/KeyResultFullWidth";
import { Key } from "@mui/icons-material";
const Intro = () => {
  return (
    <div>
      <div className="mb-100 container">
        <CaseStudyIntro
          copy="
      Personalized email marketing for hundreds of ecommerce brands"
          blocks={[
            {
              title: "Areas",
              copy: [
                "Growth Design",
                "Product Strategy",
                "A/B Testing",
                "Visual Design",
              ],
            },
            {
              title: "Teams I worked with",
              copy: [
                "Customer Success",
                "Engineers",
                "Product Managers",
                "Project Managers",
              ],
            },
          ]}
          overviewCopy={
            <>
              <p className="mb-10">
                As a UX designer, I worked on Wunderkind’s Customer Experience
                team. My works focused on email marketing and ecommerce onsite
                integration. My contributions focus on the following aspects:{" "}
              </p>
              <ul>
                <li>Owned the CX strategy for 20+ strategic accounts.</li>
                <li>
                  Designed and developed desktop and mobile assets using
                  HTML/CSS.
                </li>
                <li>Conducted iterative A/B testing to optimize solutions. </li>
                <li>
                  Research on customer behavior and designed AI-driven product
                  recommendation templates to drive CTR and conversion.
                </li>
              </ul>
            </>
          }
        />
      </div>
      <section className="py-20" style={{ backgroundColor: "#FAFAFF" }}>
        <div className="container">
        <div className="mb-40 ">
          <Paragraph
            subTitle="Impact"
            s
            copy="My time at Wunderkind was full of fun and cool achievements. Here are the numbers that represented my time at Wunderkind"
          />
        </div>
        <KeyResult
          blocks={[
            {
              title: "700+ ",
              copy: ["client brands served"],
            },
            {
              title: "20+",
              copy: ["strategic accounts customization"],
            },
            {
              title: "2x   ",
              copy: ["increase in list growth for client brands"],
            },
            {
              title: "10%  ",
              copy: [
                "increase in click-through rate compared to client’s old emails",
              ],
            },
          ]}
        /></div>
      </section>
    </div>
  );
};

export default Intro;
