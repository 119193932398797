import discoverVideo from "../images/home-page/DiscoverHomePage.mp4";
import fallbackImage from "../images/home-page/FallbackImage.png";
const videoContent = [
  {
    id: "0",
    params: "discover",
    image: discoverVideo,
    fallbackImage: fallbackImage,
    copy: "Building proactive product recommendations for e-commerce marketing",
    year: "2022",
  },
];
export default videoContent;
