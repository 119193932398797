import React from "react";
import classes from "./Section.module.css";

const Image = (props) => {
  return (
    <div>
      <div>
        {props.image && (
          <img src={props.image} alt={props.alt} width="100%" loading="lazy" />
        )}
      </div>
      <div>
        {props.copy && (
          <span className={classes.annotation}>{props.annotation}</span>
        )}
      </div>
    </div>
  );
};

export default Image;
