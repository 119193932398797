import React from "react";
import classes from "./Section.module.css";
import Image from "./Image";
const imageFullWidth = (props) => {
  return (
    <section className={` ${props.classes}`}>
      <div className={classes.gridContainer}>
        <div className={classes.divBlock2}></div>{" "}
        <div
          className={` ${classes.divBlock10} ${classes.fullWidth} ${classes.imageAnnotationContainer}`}
        >
         <Image image={props.image} alt={props.alt} annotation={props.annotation}/>
        </div>
      </div>
    </section>
  );
};

export default imageFullWidth;
