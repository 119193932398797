import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import ProtectedRoute from "../../context/ProtectedRoute";
import NavBar from "../../components/NavBar/NavBar";

import Footer from "../../components/Footer/FooterNew";
import ProjectFooter from "../../components/Footer/ProjectFooter";

import Homepage from "../Homepage";
import Info from "../Info";
import MedicalSchoolProject from "../projects/MedicalSchoolProject";
import EmailDesignsProject from "../projects/EmailDesignsProject";
import DiscoverProject from "../projects/DiscoverProject";
import WunderkindProject from "../projects/WunderkindProject";

import ReactGA from "react-ga";
import projectsContent from "../../content/ProjectsContent";
import videoContent from "../../content/videoContent";
import LoginPage from "../../components/Login/Login";

const Home = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  // useEffect(() => {
  // 	const conditions = location.pathname === '/emaildesigns' || location.pathname === '/wunderkind';
  // 	if (conditions) {
  // 		document.body.style.color = '#000000';
  // 		document.body.style.backgroundColor = '#f7f7f8';
  // 	} else {
  // 		// Default color scheme
  // 		document.body.style.color = '#FFFFFF';
  // 		document.body.style.backgroundColor = '#222222';
  // 	}
  // }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<NavBar />} />
        <Route path="medicalschool" element={<NavBar />} />
        {/* <Route path="wunderkind" element={<NavBarWunderkind />} /> */}
        <Route path="discover" element={<NavBar />} />
        <Route path="emaildesigns" element={<NavBar />} />

        <Route path="/*" element={<NavBar />} />
      </Routes>

      <main className="main">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="info" element={<Info />} />
          {/* <Route path="wunderkind" element={<WkndProject />} /> */}
          <Route path="medicalschool" element={<MedicalSchoolProject />} />
          <Route path="emaildesigns" element={<EmailDesignsProject />} />
          <Route path="discover" element={<WunderkindProject />} />
        </Routes>
      </main>
      <Routes>
        <Route path="/" element={<Footer />} />
        <Route path="info" element={<Footer />} />
        <Route path="/login" element={<LoginPage />} />


        {projectsContent.map((project) => {
          return (
            <Route
              key={project.id}
              path={project.params}
              element={<ProjectFooter idx={project.id} />}
            />
          );
        })}
        {videoContent.map((project) => {
          return (
            <Route
              key={project.id}
              path={project.params}
              element={<ProjectFooter idx={project.id} />}
            />
          );
        })}
      </Routes>
    </>
  );
};

export default Home;
