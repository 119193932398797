import Paragraph from "../../../shared/Sections/Paragraph";
import TitleInsight from "../../../shared/Sections/TitleInsight";

const Ideation = () => {
  return (
    <>
      <section class="content-block">
        <Paragraph
          title="04"
          subTitle="Opportunity"
          classesH1="mb-50"
          subTitle2="The big question"
          copy="How might we create a system that connect student needs and medical professional practices? After multiple rounds of interviews and auditing the existing systems, it became clear where we needed to focus our attention heading into design."
        />
        <TitleInsight
          subTitle="Solving the value problem"
          blocks={[
            {
              itemTitle: "Bridge data disconnect",
              copy: "Creating structured student encounter logs that incorporate a patient-tracking over time, allowing students to build a cohesive understanding of patient journeys.",
            },
            {
              itemTitle: "Education-driven documentation",
              copy: "Align documentation practices with both educational and clinical needs, instructing students to practice charting used by professional while enhancing the educational value of patient encounter documentation.",
            },
          ]}
        />

        <TitleInsight
          subTitle="Making it easy for students to adopt"
          blocks={[
            {
              itemTitle: "Streamline onboarding with guidance",
              copy: "Guide students on the importance of accurate and timely patient logs, as well as how to meet professional expectations in writing and maintaining the logs.",
            },
          ]}
        />
      </section>
    </>
  );
};
export default Ideation;
