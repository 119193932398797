import React from "react";
import { ReactComponent as CodeIcon } from "../../images/icons/code.svg";
import classes from "./Footer.module.css";

const FooterNew = () => {
  return (
    <div style={{background:"#F4F7FD"}}>
      <div className="gridContainer py-10">
        <div className="divBlock3">
          <h3 className="displaySm">Xiaoxuan Wu</h3>{" "}
        </div>
        <div className="divBlock4">
          <h3 className="titleMd mb-10">
            Thanks for visiting, let’s get in touch
          </h3>

          <p className="bodySm mb-10">
            I’m a digital product designer with a focus on web platforms. If you
            want to learn more about what I do in detail, don’t hesitate to
            reach out! Let’s talk freelance, grab a (digital) coffee, or just be
            friends.
          </p>
          <div className="flex mb-5" style={{ alignItems: "center" }}>
            <span className="titleSm " style={{ fontSize: ".8rem" }}>
              Made with &nbsp;
            </span>
            <CodeIcon />
            <span>&nbsp; by me.</span>
          </div>
          <p className="titleSm" style={{ fontSize: ".8rem" }}>
            Last updated: September 27, 2023
          </p>
        </div>
        <div className="divBlock"></div>

        <div className="divBlock2">
          <h3 className="titleMd mb-10">Navigate</h3>
          <p className="titleSm">
            <a
              href="https://www.xiaoxuan.work"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              All works{" "}
            </a>
          </p>
          <p className="titleSm">
            <a
              href="https://www.xiaoxuan.work/about"
              target="_blank"
              className={classes.link}
            >
              About me
            </a>
          </p>
        </div>
        <div className="divBlock2">
          <h3 className="titleMd mb-10">Contact</h3>
          <p className="titleSm">
            <a
              href="https://www.linkedin.com/in/xiaoxuan-wu-421775156/"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              LinkedIn
            </a>
          </p>
          <p className="titleSm">xiaoxuann.wu@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default FooterNew;
