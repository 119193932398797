import React from "react";

import Paragraph from "../../../../shared/Sections/Paragraph";
import ParagraphWithQuotes from "../../../../shared/Sections/ParagraphWithQuotes";
import Image from "../../../../shared/Sections/Image";
import DisplayTitle from "../../../../shared/Sections/DisplayTitle";
import Insights2Column from "../../../../shared/Sections/Insights2Column";
import SegmentationBefore from "../../../../images/Wunderkind/Segmentation builder before.png";
import SegmentationAfter from "../../../../images/Wunderkind/Segmentation builder after.png";
import ThumbUpIcon from "../../../../images/icons/Thumbs-up-outline.svg";
import ThumbDownIcon from "../../../../images/icons/Thumbs-down-outline.svg";
import Video from "../../../../shared/Sections/VideoFullWidth";
import CreateCampaigns from "../../../../images/Wunderkind/Create campaigns.png";
import FlexibleEmailBuilder from "../../../../images/Wunderkind/Flexible product grid.mp4";
import BrandManagement from "../../../../images/Wunderkind/Modular brand management.png";
import { icon } from "@fortawesome/fontawesome-svg-core";
const FinalDesign = () => {
  return (
    <div className="">
      {/* <div className="container"> */}
      {/* <DisplayTitle
          overline="Module 1 - brand management"
          title="One hub for all creatives "
        />
        <ParagraphWithQuotes
          subTitle="A modular approach to brand management"
          kpi="KPI: Flexible"
          copy="The modular atomic design system breaks down design into fundamental components, encompassing styles, blocks, and templates. Since many client design teams already use modular design systems, our approach aligns with their existing workflows and familiarity. This approach minimizes repetitive manual edits when creating campaign assets, streamlining the creative process and improving efficiency."
        />
      </div>
      <div className="py-20">
        <Image image={BrandManagement} />
      </div>
      <div className="container">
        <DisplayTitle
          overline="Module 2 - Campaign builder"
          title="Empowering marketers with easy campaign creation"
        />
        <div className="mb-20">
          <ParagraphWithQuotes
            subTitle="Intuitive campaign creation"
            copy="Testing and segmentation drives overall campaign goals. Its critical to reduce friction and confusion at this stage. "
            quotes="“Having a very easy interface that both the CSM and the client can understand, I think will just also enable clients to hopefully then feel encouraged to send more...if they have an easy, easy interface to do so.” –– Anna, Customer Success Manager"
          />
        </div>
      </div> */}
      <div className="container">
        <div className="mb-50">
          <Paragraph
            subTitle="Simplifying customization"
            copy="To give users enough customization options without overwhelming them, I created component-based solutions and broke down the possible product grid layouts based on alignment, columns, and product details. This allows users to easily mix and match elements to create their desired designs."
          />
        </div>
        <Video video={FlexibleEmailBuilder} />
      </div>

      <div>
        <Paragraph />
      </div>
    </div>
  );
};

export default FinalDesign;
