import CaseStudyIntro from "../../../shared/Sections/Intro";
import MedicalHero from "../../../images/navheadshot/project-page-hero-medical.png";

const MedicalOverview = () => {
  return (
    <>
      <CaseStudyIntro
        copy="How might we help medical students learn and grow documentation skills through clinical rotation patient encounter logs? "
        blocks={[
          {
            title: "Project Timeline",
            copy: ["Jan-March 2021 (12 weeks)"],
          },
          {
            title: "Team",
            copy: ["Eric Kim, Mae Hubel"],
          },
        ]}
        image={MedicalHero}
        overviewCopy={
          <>
            Documentation plays a pivotal role in healthcare. As a part of
            clinical rotation in medical schools, students are required to
            document their patient encounters digitally.
            <br></br> <br></br>
            In collaboration with WashU Medical School, we were tasked to design
            the patient logging system for medical students. This project is a
            part of the Interaction Design for Health and Wellbeing class. At
            the project's conclusion, we presented our solution to medical
            program directors and students.
          </>
        }
      />
    </>
  );
};

export default MedicalOverview;
