import Paragraph from "../../../shared/Sections/Paragraph";
import ImageParagraph from "../../../shared/Sections/ImageParagraph";
import ImageFullWidth from "../../../shared/Sections/ImageFullWidth";
import OKLHightlight from "../../../images/email-designs/Headshot2.png";
import KeyResult from "../../../shared/Sections/KeyResultFullWidth";
import CaseStudyIntro from "../../../shared/Sections/Intro";

const OKL = () => {
  return (
    <>
      {" "}
      <section class="content-block">
        <Paragraph
          title="01"
          classesH1="mb-50"
          subTitle="Case study: One Kings Lane"
          subTitle2="Refreshing CX strategy"
          copy="OKL has been with Wunderkind for several years without any significant updates to their strategy. As a legacy client, they were seeking a comprehensive strategic overhaul to breathe new life into their existing approaches.

"
        />

        <KeyResult
          blocks={[
            {
              title: "12.5% ",
              copy: ["of total digital revenue from triggered email"],
            },
            {
              title: "#2 ",
              copy: ["highest paid channel at driving conversions"],
            },
            {
              title: "5x ",
              copy: ["increase in triggered email revenue"],
            },
          ]}
        />
        <ImageFullWidth image={OKLHightlight} />
      </section>
    </>
  );
};

export default OKL;
