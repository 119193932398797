import React from "react";
import classes from "./Section.module.css";

const DisplayTitle = (props) => {
  return (
    <div className="mb-30" style={{ width: "100%", maxWidth: "1440px" }}>
      <div>
        {props.overline && <h3 className="overline mb-10">{props.overline}</h3>}
        {props.title && (
          <h1 className="displaySm" style={{ lineHeight: "3rem" }}>
            {props.title}
          </h1>
        )}
      </div>
    </div>
  );
};

export default DisplayTitle;
