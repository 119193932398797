import React from "react";
import classes from "../Info/AboutMe.module.css";

const Awards = () => {
  return (
    <div className={` ${classes.grayContainer}`}>
      <h3 className="mb-10 displaySm ">Awards</h3>
      <h3 className="titleLg">MIT GrandHack Hacking Medicine </h3>
      <p>3rd place winner</p>
    </div>
  );
};

export default Awards;
