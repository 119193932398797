import React, { useState, useEffect } from "react";
import Intro from "./Sections/Intro";
import Context from "./Sections/Context";
import DiscoverIntro from "./Sections/discovery email/Intro";
import Opportunities from "./Sections/Opportunities";
import TwoProjectSummary from "./Sections/TwoProjectSummary";
import DesignPrinciple from "./Sections/discovery email/DesignPrinciple";
import Solution from "./Sections/discovery email/Solution";
import Project2Intro from "./Sections/Autonomou platform/Intro";
import Project2Research from "./Sections/Autonomou platform/ResearchSummary";
import Project2Solution from "./Sections/Autonomou platform/FinalDesign";
const Wunderkind = () => {
  return (
    <div className="mb-100">
      <Intro />
      <Context />
      <Opportunities />
      <TwoProjectSummary />
      <DiscoverIntro />
      <DesignPrinciple />
      <Solution />
      <Project2Intro />
      <Project2Research />
      <Project2Solution />
      
    </div>
  );
};

export default Wunderkind;
