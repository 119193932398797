import React from "react";
import DisplayTitle from "../../../../shared/Sections/DisplayTitle";
import Paragraph from "../../../../shared/Sections/Paragraph";
const ResearchSummary = () => {
  return (
    <div className="container">
      <DisplayTitle overline="Research Summary" />
      <Paragraph
        subTitle="Insights and opportunities"
        copy="We were able to distill our findings into a few key problems we want to address, and a few key goals which would help guide any design decisions thereafter."
      />
    </div>
  );
};

export default ResearchSummary;
