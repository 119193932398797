import React from "react";
 import { ReactComponent as CodeIcon } from "../../images/icons/code.svg"
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={`${classes.footer} footer`}>
      <div
        style={{ marginBottom: `30px` }}
        className={classes["divider"]}
      ></div>
      <div className={classes["footerMainContent"]}>
        <div className={classes["footerItemLeft"]}>
          <h3>Thanks for visiting!</h3>
          <p className="bodySm">
            Made with &nbsp;
         <CodeIcon/>
            &nbsp; by me.{" "}
          </p>
        </div>
        <div className={classes["footerItemRight"]}>
          <p>
            <a
              href="https://www.linkedin.com/in/xiaoxuan-wu-421775156/"
              target="_blank"
              rel="noreferrer"
              className={classes["link"]}
            >
              LinkedIn
            </a>
            <br />
            <a
              href="mailto:xiaoxuann.wu@gmail.com"
              className={classes["link"]}
              target="_blank"
              rel="noreferrer"
            >
              Contact Me
            </a>
          </p>

          <p className="bodySm">
            Last updated: September 27, 2023
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
