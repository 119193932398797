import React from 'react';
import MedicalSchool from '../../components/MedicalSchool/MedicalSchool';

const MedicalSchoolProject = () => {
	return (
		<>
			<MedicalSchool />
		</>
	);
};

export default MedicalSchoolProject;
