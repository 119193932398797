import Paragraph from "../../../shared/Sections/Paragraph";
import Accordion from "../../../shared/Sections/Accordion";
const Retro = () => {
  return (
    <>
      <section className="content-block">
        <Paragraph
          title="08"
          subTitle="Retrospectives"
          subTitle2="Navigating challenges and personal growth"
          classesH1="mb-50"
          copy="Working at Wunderkind has been an eye-opening journey filled with challenges and personal growth. Here are some key takeaways from my experience.    "
        />
        <Accordion
          items={[
            {
              title: "Client diversity: no one-size-fits-all solution",

              content:
                "One aspect of agency life that constantly intrigues me is how strategies evolve when applying the same product to different clients. There's no universal solution and each client brings their unique needs and goals to the table. This diversity keeps me on my toes, pushing me to adapt and innovate continually. ",
            },
            {
              title:
                "Unclear client expectations and uncovering unspoken needs",

              content:
                "One of the most significant challenges in my role is dealing with clients who lack a clear vision of their needs. As designers, we often need to proactively suggest solutions that address unspoken needs. This demands creative thinking and resourcefulness when auditing websites to identify improvement opportunities. Despite limited resources and time for each client, the true value lies in uncovering actionable insights that drive meaningful improvements.",
            },
            {
              title: "Prototypes for effective communication  ",
              content:
                "To bridge the gap between abstract ideas and client expectations, I've found that developing low-fidelity prototypes and wireframes is immensely beneficial. Clients tend to respond more effectively to tangible representations of design concepts rather than abstract discussions. It's a way to make our ideas come to life and facilitate more productive feedback sessions.      ",
            },
            {
              title: "Managing legacy clients",
              content:
                "One of the more delicate challenges is working with legacy clients who might be at risk of churning. These clients may not always be communicative, making it essential to have compelling strategies in place to convince them to stay. This involves not only delivering results but also showing them the value of our ongoing partnership.",
            },
          ]}
        />
      </section>
    </>
  );
};

export default Retro;
