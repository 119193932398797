import React from "react";
import classes from "./Section.module.css";

const SectionDivider = (props) => {
  return (
    <section className={` ${props.classes}`}>
      <div className={classes.gridContainer}>
        <div className={classes.divBlock2}></div>{" "}
        <div className={`${classes.divBlock10}`}>
          <div className={`${classes.sectionDivider} `}> </div>
       
        </div>
      </div>
    </section>
  );
};

export default SectionDivider;
