import React, { useState, useEffect, useRef } from "react";
import classes from "./Section.module.css";
import { Link as ScrollLink, animateScroll } from "react-scroll"; // Import ScrollLink from react-scroll
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from react-router-dom
import debounce from "lodash.debounce"; // Import debounce function
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const SideBar = (props) => {
  const [activeSection, setActiveSection] = useState(""); // State to track active section
  const [isScrolling, setIsScrolling] = useState(false);
  const [sectionOffsets, setSectionOffsets] = useState([]);
  const lastAnimationFrame = useRef(null);
  const isClicking = useRef(false);

  const handleScroll = debounce(() => {
    const sectionOffsets = props.blocks.map((obj) => {
      const sectionElement = document.getElementById(obj.id);
      if (sectionElement) {
        return {
          id: obj.id,
          offset: sectionElement.offsetTop,
          height: sectionElement.offsetHeight,
        };
      }

      return null;
    });

    const currentPosition = window.scrollY;
    for (const section of sectionOffsets) {
      if (
        section &&
        currentPosition >= section.offset &&
        currentPosition + 1 < section.offset + section.height
      ) {
        setActiveSection(section.id);

        break;
      }
    }
    setSectionOffsets(sectionOffsets);
  }, 50);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLinkClick = (sectionId) => {
    // setActiveSection(sectionId);
    setTimeout(() => {
      setActiveSection(sectionId);
      setIsScrolling(true);
      // Scroll to the clicked section (optional)
      const sectionElement = document.getElementById(sectionId);

      if (sectionElement) {
        animateScroll.scrollTo(sectionElement.offsetTop, {
          duration: 1000, // Duration of the scroll animation in milliseconds
          delay: 0,
          smooth: "easeInOutCubic", // You can customize this easing function
          onSetScrolling: (isScrolling) => {
            if (!isScrolling) {
              // Scrolling animation is complete
              setIsScrolling(false);
            }
            // Update the active section after scrolling is complete
            if (isClicking.current) {
              // Delay the active section update after a click event
              setTimeout(() => {
                console.log("Delay function called");

                isClicking.current = false;
                handleScroll();
              }, 300); // Adjust the delay duration as needed
            }
          },
        });

        // Set up a custom animation loop
        const animateActiveSection = () => {
          const currentPosition = window.scrollY;
          for (const section of sectionOffsets) {
            if (
              section &&
              currentPosition >= section.offset &&
              currentPosition + 1 < section.offset + section.height
            ) {
              setActiveSection(section.id);
              break;
            }
          }

          if (isScrolling) {
            lastAnimationFrame.current =
              requestAnimationFrame(animateActiveSection);
          }
        };

        // Start the custom animation loop
        lastAnimationFrame.current =
          requestAnimationFrame(animateActiveSection);
      }
    }, 1200); // Adjust the delay duration as needed
  };

  useEffect(() => {
    return () => {
      // Cancel the last animation frame when component unmounts
      if (lastAnimationFrame.current) {
        cancelAnimationFrame(lastAnimationFrame.current);
      }
    };
  }, []);

  //     // Set active section after scrolling transition is complete
  //     setTimeout(() => {
  //       setIsScrolling(false);
  //     }, 800);
  //   }
  // };

  return (
    <section className={`${classes.sideBar} ${props.className}`}>
      {/* <RouterLink to="/" className={classes.backButton}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={classes.icon}
          size="lg"
        />
        <p>Back to Projects</p>
      </RouterLink> */}

      <h2>Content</h2>

      {props.blocks.map((obj, index) => (
        <div key={index}>
          <ScrollLink
            to={obj.id}
            className={`${classes.sidebarLink} ${
              activeSection === obj.id ? classes.activeLink : ""
            }`}
            spy={true}
            smooth={true}
            onClick={() => handleLinkClick(obj.id)}
          >
            <p className="label">{obj.title}</p>
          </ScrollLink>
        </div>
      ))}
    </section>
  );
};

export default SideBar;
