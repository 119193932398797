import Home from "./pages/Home/Home";
import React from "react";

import "./App.css";
import ReactGA from "react-ga";


function App() {
  return <Home />;
}

export default App;

