import { NavLink, useLocation } from "react-router-dom";

import classes from "./NavBar.module.css";
import logoWhite from "../../images/Logo.png";
import React, { useState, useEffect, useRef } from "react";

const NavBar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(null);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Set the fadeIn state to true after a delay of 3 seconds
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 500);

    // Clean up the timer to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    // Check the current location and set the active link accordingly
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleMouseEnter = (path) => {
    // Handle mouse enter event
    if (activeLink !== path) {
      setActiveLink(path);
    }
  };

  const handleMouseLeave = () => {
    // Handle mouse leave event
    // Recheck and update the active state
    setActiveLink(location.pathname);
  };

  //scroll-triggered nav bar

  const headerRef = useRef(null);
const [prevScrollPos, setPrevScrollPos] = useState(0);
const [isVisible, setIsVisible] = useState(true);

useEffect(() => {
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingUp = prevScrollPos > currentScrollPos;

    if (isScrollingUp) {
      setIsVisible(true); // Always show the header when scrolling up
    } else {
      setIsVisible(currentScrollPos <= 60); // Show the header only at the top
    }

    setPrevScrollPos(currentScrollPos);
  };

  const handleMouseMove = (e) => {
    // Check if the mouse Y-coordinate is within the top 60px of the screen
    if ((e.clientY <= 60 || window.pageYOffset === 0) || isVisible) {
      setIsVisible(true); // Show the header when not at the top and mouse is in the top 60px
    } else {
      setIsVisible(false); // Hide the header
    }
  };

  // Add event listeners for both scroll and mousemove events
  window.addEventListener("scroll", handleScroll);
  document.addEventListener("mousemove", handleMouseMove);

  return () => {
    // Remove the event listeners when the component unmounts
    window.removeEventListener("scroll", handleScroll);
    document.removeEventListener("mousemove", handleMouseMove);
  };
}, [prevScrollPos]);


  return (
    <header
      ref={headerRef}
      className={`${classes.header}  ${fadeIn ? "fade-in" : ""}  ${
        isVisible ? "visible" : "hidden"
      }`}
    >
      <div className="navContainer">
        <NavLink to="/">
          <img
            className="logo"
            alt="Xiaoxuan's Portfolio"
            src={logoWhite}
          />
        </NavLink>

        <nav className={classes["main-nav"]}>
          <ul>
            <li>
              <NavLink
                to="/"
                className={`${classes["main-nav-link"]} ${
                  activeLink === "/" ||
                  activeLink === "/discover" ||
                  activeLink === "/emaildesigns" ||
                  activeLink === "/medicalschool"
                    ? classes.active
                    : ""
                }`}
                onMouseEnter={() => handleMouseEnter("/")}
                onClick={() => setActiveLink("/")}
                onMouseLeave={handleMouseLeave} // Add onMouseLeave event handler
              >
                Work
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/info"
                className={`${classes["main-nav-link"]} ${
                  activeLink === "/info" ? classes.active : ""
                }`}
                onMouseEnter={() => handleMouseEnter("/info")}
                onClick={() => setActiveLink("/info")}
                onMouseLeave={handleMouseLeave} // Add onMouseLeave event handler
              >
                Info
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default NavBar;
