import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './shared/UI/ScrollToTop';
import { AuthContextProvider } from './context/auth-context';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { fa1,fa2,fa3,fa4 } from "@fortawesome/free-solid-svg-icons";

// Add the icon to the library
library.add(faArrowLeft, fa1, fa2, fa3, fa4);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<AuthContextProvider>
				<ScrollToTop />
				<App />
			</AuthContextProvider>
		</BrowserRouter>
	</React.StrictMode>
);
