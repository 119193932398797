import ImageParagraph from "../../../shared/Sections/ImageParagraph";
import Paragraph from "../../../shared/Sections/Paragraph";
import Insight from "../../../shared/Sections/Insights";

import ImageFullWidth from "../../../shared/Sections/ImageFullWidth";
import currentFlow from "../../../images/MedicalSchool/Current flow.png";
import updatedFlow from "../../../images/MedicalSchool/Updated flow.png";
import Insights2Column from "../../../shared/Sections/Insights2Column";

const UpdatedFlow = () => {
  return (
    <>
      {" "}
      <section class="content-block">
        <Paragraph
          title="05"
          subTitle="User flow"
          classesH1="mb-50"
          subTitle2="Thinking about the path..."
          copy="We started our design phase by restructuring the flow, which helped us pinpoint the data disconnect issue within the macro-level navigation and identify appropriate locations to integrate new ideas that align with our users' mental model. Visualizing it also ensures team-wide alignment on our current position and simplifies communication with users for feedback gathering."
        />
        <Paragraph
          subTitle2="Before: Administrative perspective"
          copy="The system lacked the intuitive design and accessibility necessary for students to seamlessly navigate through their tasks and engagements."
        />
        <div>
          <ImageFullWidth image={currentFlow} />
          <Insights2Column
            blocks1={[
              {
                copy: "Students manage concurrent clerkships. Current navigation complicates switching between clerkships for log entry.",
              },
              {
                copy: "Current user flow prioritizes the school administrator's perspective, neglects addressing the needs of the students.            ",
              },
              {
                copy: "Patient history lacks data continuity       ",
              },
            ]}
            blocks2={[
              {
                copy: 'Documentation offers minimal educational value; selection boxes merely fulfill "requirements."',
              },
              {
                copy: "Guidelines are unclear for completing optional notes.      ",
              },
              {
                copy: "Submissions lack follow-up actions.            ",
              },
            ]}
          />
        </div>
      </section>
      <section class="content-block">
        <Paragraph
          subTitle2="After: Alignment with EMR practices"
          copy="Model the information architecture after the common EMR structure, emphasizing patients, encounters, and associated logs. This familiarity can make navigation more intuitive for medical students.
    "
        />
        <ImageFullWidth image={updatedFlow} />
      </section>
    </>
  );
};
export default UpdatedFlow;
