import React from "react";
import Solution1 from "../../../images/Wunderkind/Project Summary 1.png";
import Solution2 from "../../../images/Wunderkind/Project Summary 2.png";
const ProblemSolutionComponent = (props) => {
  return (
    <div className="problem-solution">
      <p className="overline mb-10">{props.problem}</p>
      <h3 className="titleLg mb-10">{props.solutionTitle}</h3>
      <p>{props.solutionCopy}</p>
      <p>{props.status}</p>
    </div>
  );
};
const TwoProjectSummary = () => {
  return (
    <section>
      <div
        className="container"
        style={{ backgroundImage: "linear-gradient(white, #FFEFD1)" }}
      >
        <div className="gridContainer mb-50">
          <div className="divBlock1"></div>
          <div className="divBlock10">
            <h1 className="displaySm">What it leads to</h1>
          </div>
        </div>
        <div className="gridContainer" style={{ alignItems: "center" }}>
          <div className="divBlock1"></div>
          <div className="divBlock5">
            <ProblemSolutionComponent
              problem="Problem 1: Low repeat purchase"
              solutionTitle="Solution 1: Product discovery email"
              solutionCopy="    How might we drive conversion rates, driving loyalty, repeat
            purchases, and incremental revenue beyond the first purchase for our
            clients?"
              status="Launched in 2022 Q4"
            />
          </div>
          <div className="divBlock1"></div>

          <div className="divBlock3">
            <img src={Solution1} />
          </div>
        </div>
      </div>

      <div style={{ backgroundImage: "linear-gradient( #FFEFD1, white)" }}>
        <div className="flex" style={{ gap: "100px", padding: "200px 0" }}>
          <div style={{ width: "50%" }}>
            <img src={Solution2} />
          </div>
          <div style={{ width: "35%" }}>
            <ProblemSolutionComponent
              problem="Problem 2: Scalibiitly"
              solutionTitle="Solution 2: Autonomous marketing platform"
              solutionCopy="How might we support cross-functional collaboration and productivity while balancing customization with templated solutions to meet the diverse needs of clients across various industries and sizes."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TwoProjectSummary;
