import React from "react";

import classes from "./Section.module.css";

const TitleInsight = (props) => {
  return (
    <>
      <div className={classes.gridContainer}>
        <div className={classes.divBlock2}></div>
        <div className={classes.divBlock4}>
          {props.subTitle && <h3 className="mb-10">{props.subTitle}</h3>}
        </div>

        <div className={`${classes.insightContainer} ${classes.divBlock5}`}>
          {props.blocks.map((props, index) => (
            <div className={classes.iconContentContainer} key={index}>
              <div className={classes.insightItem}>
                {props.icon && <div className="icon"> {props.icon}</div>}
                {props.itemTitle && (
                  <h4 className="mb-10">{props.itemTitle}</h4>
                )}
                {props.copy && <p>{props.copy}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TitleInsight;
