import React from "react";
import { ReactComponent as Figma } from "../../images/icons/Figma.svg";
import { ReactComponent as Premiere } from "../../images/icons/Premiere Pro.svg";
import { ReactComponent as AfterEffect } from "../../images/icons/AE.svg";
import { ReactComponent as Photoshop } from "../../images/icons/Photoshop.svg";
import { ReactComponent as Lightroom } from "../../images/icons/Lightroom.svg";
import { ReactComponent as Illustrator } from "../../images/icons/Illustrator.svg";
import classes from "../Info/AboutMe.module.css";

const TechnicalSkills = () => {
  return (
    <div className={classes.grayContainer}>
      <h3 className="displaySm mb-10">Technical Skills</h3>
      <div className={classes.technicalContainer}>
        <div>
          <p className="titleLg mb-10">Software skills</p>
          <div className={classes.softwareContainer}>
            <Figma />
            <Photoshop />
            <AfterEffect />
            <Premiere />
            <Lightroom />
            <Illustrator />
          </div>
        </div>
        <div>
          <p className={`titleLg mb-10`}>Coding skills</p>
          <div className={classes.codeContainer}>
            <ul>
              <li>HTML/CSS</li>
              <li>JavaScript</li>
              <li> React</li>
            </ul>
            <ul>
              <li>Next.js </li>
              <li>Wordpress</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalSkills;
