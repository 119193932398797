import React from "react";

import classes from "./Section.module.css";

const CaseStudyIntro = (props) => {
  return (
    <section className={`${classes["caseStudyIntro"]} ${props.classes}`}>
      <div className={classes.gridContainer}>
        <div className={classes.divBlock2}></div>

        <div
          className={` ${classes.introTitleImageContainer} ${classes.divBlock10}`}
        >
          <h1 className="displayMd mb-10">{props.copy}</h1>
          {/* <div className={classes.imageContainer}>
            {props.image && (
              <img
                src={props.image}
                alt={props.alt}
                width="100%"
                loading="lazy"
              />
            )}
          </div> */}
        </div>
        <div className={classes.divBlock2}></div>
        <div className={classes.divBlock2}>
          <h3>Overview</h3>
        </div>

        <div className={`${classes.divBlock6} mb-50`}>
          {props.overviewCopy && <p>{props.overviewCopy}</p>}
        </div>
        <div className={classes.divBlock2}></div>
        <div className={classes.divBlock4}></div>
        {props.blocks.map((obj, index) => (
          <div className={classes.introItems} key={index}>
            <h2 className="titleMd">{obj.title}</h2>
            {obj.copy.map((name, idx) => (
              <p className={classes.introCopy} key={idx}>
                {name}
              </p>
            ))}
          </div>
        ))}

        <div className={classes.divBlock2}></div>
        <div className={classes.divBlock4}></div>

        {props.blocks2 &&
          props.blocks2.map((obj, index) => (
            <div className={`${classes.introItems} tp-50`} key={index}>
              <h2 className="titleMd">{obj.title}</h2>

              {obj.copy.map((item, idx) => (
                <div key={idx}>
                  {item.type === "text" ? (
                    <p>{item.value}</p>
                  ) : item.type === "link" ? (
                    <p>
                      <a
                        href={item.url}
                        style={{ color: "black", textDecoration: "underline" }}
                      >
                        {item.text}
                      </a>
                    </p>
                  ) : null}
                </div>
              ))}
            </div>
          ))}
      </div>
    </section>
  );
};

export default CaseStudyIntro;
