import React from "react";
import classes from "./Section.module.css";

const ImageParagraph = (props) => {
  return (
    <section className={`${classes.copy} ${props.classes}`}>
      <div className={classes.gridContainer}>
        <div className={classes.divBlock2}></div>
        <div
          className={`${classes.divBlock6} ${classes.imageAnnotationContainer}`}
        >
          <div className={`${classes.imageContainer} `}>
            {props.image && (
              <img loading="lazy" src={props.image} alt={props.alt} width="100%" />
            )}
          </div>
          {props.copyAnnotation && (
            <span className={classes.annotation}>{props.copyAnnotation}</span>
          )}{" "}
        </div>

        <div className={`${classes.divBlock4} ${classes.paragraphContainer}`}>
          {props.title && <h2 className="mb-10">{props.title}</h2>}

          {props.subTitle && <h1>{props.subTitle}</h1>}
          {props.subTitle2 && <h3>{props.subTitle2}</h3>}

          <br></br>

          {props.copy && <p>{props.copy}</p>}
        </div>
      </div>
    </section>
  );
};

export default ImageParagraph;
