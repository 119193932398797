import React from "react";
import photographyProfile from "../../images/aboutMe/Photography profile pic.jpg";
import classes from "../Info/AboutMe.module.css";
import wedding1 from "../../images/aboutMe/Wedding-1.jpg";
import wedding2 from "../../images/aboutMe/Wedding-2.jpg";
import wedding3 from "../../images/aboutMe/Wedding-3.jpg";
import wedding4 from "../../images/aboutMe/Wedding-4.jpg";
import wedding5 from "../../images/aboutMe/Wedding-5.jpg";
import wedding6 from "../../images/aboutMe/Wedding-6.jpg";
import wedding7 from "../../images/aboutMe/Wedding-7.jpg";

import YS1 from "../../images/aboutMe/YS-1.jpg";
import YS2 from "../../images/aboutMe/YS-2.jpg";

import portrait1 from "../../images/aboutMe/Portrait-1.jpg";
import Couples1 from "../../images/aboutMe/Couple-2.jpg";
import Couples2 from "../../images/aboutMe/Couple-3.jpg";


const Photography = () => {
  return (
    <div className="">
      <div className="py-20 gridContainer">
        <h2 className="displayMd divBlock12">
          Working with people for people is at the heart of what I do.
        </h2>
      </div>
      <div className="gridContainer">
        <div className="divBlock1"></div>

        <div className="divBlock6">
          <h3 className="">When I’m not designing..</h3>
          <br></br>

          <p>
            Outside of work, I spend most of my time capturing moments with my
            camera. I love taking portraits and building 1 to 1 connections with
            people. I’ve worked with over 20 clients ranging from portraits,
            couples, pets, graduation, and city hall weddings.
          </p>
          <br></br>
          <p>
            Living and working in New York City has offered me the opportunity
            to engage with diverse stories and experiences, I find great
            pleasure in uncovering the beauty in ordinary people and helping
            them share their stories through my lens.
          </p>
          <br></br>
          <p>
            Building connections with people is at the heart of what I do, and I
            find endless inspiration in the unique stories each person brings.
          </p>
        </div>
        <div className="divBlock1"></div>
        <div className="divBlock3">
          <div className="imageContainer">
            <img
              src={photographyProfile}
              alt="Profile Picture"
              width="100%"
              loading="lazy"
              className={classes.imageVisible}
            />
          </div>
        </div>
      </div>
      <div className={`${classes.gallary} py-20`}>
        <div
          className="imageContainer"
          style={{
            gridColumn: "1/5",
            marginTop: "20%",
          }}
        >
          <img
            src={wedding1}
            alt="Profile Picture"
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>
        <div
          className="imageContainer"
          style={{
            gridColumn: "7/13",
            gridRow: "1/2",
          }}
        >
          <img
            src={wedding7}
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>
        <div
          className="imageContainer"
          style={{
            gridColumn: "5/8",
            gridRow: "1/2",
            marginTop: "80%",
          }}
        >
          <img
            src={wedding6}
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>
        <div
          className="imageContainer"
          style={{
            gridColumn: "9/12",
            gridRow: "1/2",
            marginTop: "110%",
          }}
        >
          <img
            src={wedding5}
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>
        <div
          className="imageContainer"
          style={{
            gridColumn: "7/10",
            gridRow: "2/3",
            marginTop: "-10%",
          }}
        >
          <img
            src={wedding2}
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>
        <div
          className="imageContainer"
          style={{
            gridColumn: "1/5",
            gridRow: "2/3",
            marginTop:"-40%",
            marginLeft: "10%",
            marginRight: "10%",

          }}
        >
          <img
            src={wedding3}
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>
        <div
          className="imageContainer"
          style={{
            gridColumn: "2/5",
            gridRow: "3/4",
            marginTop:"-50%",
            marginLeft: "10%",
            marginRight: "10%",

          }}
        >
          <img
            src={YS1}
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>
        <div
          className="imageContainer"
          style={{
            gridColumn: "5/8",
            gridRow: "2/3",
            marginTop:"100%",
            marginRight: "0%",

          }}
        >
          <img
            src={YS2}
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>

        <div
          className="imageContainer"
          style={{
            gridColumn: "9/13",
            gridRow: "2/3",
            marginTop:"35%",
            marginRight: "20%",

          }}
        >
          <img
            src={wedding4}
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>
        <div
          className="imageContainer"
          style={{
            gridColumn: "4/8",
            gridRow: "3/4",
            marginTop:"40%",
            marginRight: "0%",

          }}
        >
          <img
            src={portrait1}
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>
        <div
          className="imageContainer"
          style={{
            gridColumn: "9/12",
            gridRow: "3/4",
            marginTop:"10%",
            marginLeft: "10%",

          }}
        >
          <img
            src={Couples2}
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>
        <div
          className="imageContainer"
          style={{
            gridColumn: "9/13",
            gridRow: "3/4",
            marginTop:"140%",
            marginRight: "0%",

          }}
        >
          <img
            src={Couples1}
            width="100%"
            loading="lazy"
            className={classes.imageVisible}
          />
        </div>

      </div>
    </div>
  );
};

export default Photography;
