import { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const Word = styled(motion.span)`
  display: inline-block;
  margin-right: 0.25em;
  white-space: nowrap;
`;

const Title = styled.h1``;

export default function AnimatedTitle({ title }) {
  const ctrls = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    } else {
      ctrls.start("hidden");
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  return (
    <Title aria-label={title} role="heading">
      <motion.div
        initial="hidden"
        animate={ctrls}
        variants={{
          hidden: {},
          visible: {
            transition: {
              staggerChildren: 0.1, // Stagger the animation of Word components
            },
          },
        }}
      >
        {title.split(" ").map((word, index) => {
          return (
            <Word
              ref={ref}
              aria-hidden="true"
              key={index}
              variants={wordAnimation}
            >
              {word}
            </Word>
          );
        })}
      </motion.div>
    </Title>
  );
}
